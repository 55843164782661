import { clientPaymentRoutes } from '@frontend/routes/showcase/client/clientPaymentRoutes'
import { clientSubjectRoutes } from '@frontend/routes/showcase/client/clientSubjectRoutes'

export const clientEducationRoutes = [
  {
    path: 'education',
    name: 'client-showcase-education',

    component: () =>
      import(
        /* webpackChunkName: "ClientEducationLayout" */
        '@frontend/layouts/showcase/ClientEducationLayout.vue'
      ),

    children: [
      ...clientSubjectRoutes,
      ...clientPaymentRoutes,

      {
        path: 'purchases',
        name: 'client-showcase-purchases',
        component: () =>
          import(
            /* webpackChunkName: "client-showcase-purchases" */
            '@frontend/pages/showcase/common/Purchases.vue'
          ),
      },
    ],
  },
]
