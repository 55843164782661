<template>
  <ElModal
    :name="name"
    :width="600"
    :adaptive="true"
    :click-to-close="false"
    transition="none"
    class="modal-content"
  >
    <div class="vm--modal-content">
      <!-- TODO: i18n -->
      <h3 class="h3 text-center">Добро пожаловать в IBLS!</h3>

      <!-- TODO: i18n -->
      <h3 class="h3 text-center">Ваша оплата прошла успешно.</h3>

      <!-- TODO: i18n -->
      <div class="mb-3 text-center" :class="$style.textDarkGray">
        На указанную почту {{ email }} уже отправлена ссылка для подтверждения
        аккаунта. Перейдите по ссылке из письма, иначе часть функций платформы
        будет недоступна.
      </div>

      <!-- TODO: i18n -->
      <div class="text-center" :class="$style.textDarkGray">
        Если вы опечатались или у вас есть какие-либо другие вопросы,
        пожалуйста, свяжитесь с нами по почте:
      </div>

      <ElLink
        :label="supportEmail"
        :class="$style.textDarkGray"
        class="text-center mb-3"
        :href="supportEmailHref"
      />

      <!-- TODO: i18n -->
      <div class="text-gray text-center mb-3">
        Если вы не видите письмо, проверьте папку "Спам".
      </div>

      <!-- TODO: i18n -->
      <ElButton
        class="btn-primary mb-3"
        :disabled="isDisabledBtnSendConfirmEmail"
        label="Отправить письмо ещё раз"
        @click="onClickBtnSendConfirmEmail"
      />

      <!-- TODO: i18n -->
      <div v-if="isRendered.countdown">
        <Countdown
          v-slot="{ minutes, seconds }"
          :time="resendCodeEmailTimeout"
          @end="onStopCountdown"
        >
          <p class="text-center mb-1">
            Повторная отправка возможна через {{ minutes }} минут
            {{ seconds }} сек.
          </p>
        </Countdown>
      </div>
    </div>
  </ElModal>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'
import ElButton from '@frontend/ui/ElButton.vue'
import ElLink from '@frontend/ui/ElLink.vue'
import ElModal from '@frontend/ui/ElModal.vue'

// Время до повторной отправки смс кода (сек)
const resendCodeEmailInitialTimeout = 5 * 60 * 1000

export default {
  name: 'ModalConfirmEmail',

  components: {
    ElModal,
    ElLink,
    ElButton,
    Countdown: VueCountdown,
  },

  props: {
    email: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      name: 'modalConfirmEmail',
      supportEmail: 'so@iblschool.ru',
      resendCodeEmailTimeout: resendCodeEmailInitialTimeout,
      isCountdownRunning: false,
    }
  },

  computed: {
    supportEmailHref() {
      return `mailto:${this.supportEmail}`
    },

    isDisabledBtnSendConfirmEmail() {
      return this.isCountdownRunning
    },

    isRendered() {
      return {
        countdown: this.isCountdownRunning,
      }
    },
  },

  methods: {
    onClickBtnSendConfirmEmail() {
      this.$emit('send-confirm-email')

      this.isCountdownRunning = true
    },

    async onStopCountdown() {
      this.isCountdownRunning = false
    },

    /**
     * @public
     */
    show() {
      this.$modal.show(this.name)
    },

    /**
     * @public
     */
    hide() {
      this.$modal.hide(this.name)
    },
  },
}
</script>

<style lang="scss" module>
$dark-gray: #2f383f;

.textDarkGray {
  color: $dark-gray;
}
</style>
