import { getCategoriesFlatten } from 'Helpers/showcase/getCategoriesFlatten'
import { isNil } from 'lodash'

export default {
  categoriesFlatten(state) {
    return getCategoriesFlatten(state.categories)
  },

  category(state, getters, rootState) {
    const { categoryPrettyLink } = rootState.common.catalog

    return getters.categoriesFlatten.find(
      (category) => categoryPrettyLink === category.seo.prettyLink,
    )
  },

  parentCategory(state, getters) {
    const parentCategoryId = getters.category?.parentCategoryId

    return getters.categoriesFlatten.find(
      (category) => parentCategoryId === category.id,
    )
  },

  hasCategory(state, getters) {
    return !isNil(getters.category)
  },

  hasParentCategory(state, getters) {
    return !isNil(getters.parentCategory)
  },

  categoryLiteBySymbolicName(state) {
    return (symbolicName) =>
      state.categoriesLite.find((cat) => cat.symbolicName === symbolicName)?.seo
        ?.prettyLink
  },
}
