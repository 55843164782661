export const clientShowcaseRoutes = [
  {
    path: '',

    component: () =>
      import(
        /* webpackChunkName: "showcase" */
        '@frontend/layouts/showcase/Showcase.vue'
      ),

    meta: {
      isDisabledScroll: true,
    },

    children: [
      {
        path: '',
        name: 'client-showcase',
        component: () =>
          import(
            /* webpackChunkName: "showcase-home" */
            '@frontend/pages/showcase/common/Home.vue'
          ),
      },

      {
        path: 'catalog',
        name: 'client-showcase-catalog',
        component: () =>
          import(
            /* webpackChunkName: "showcase-catalog" */
            '@frontend/pages/showcase/common/Catalog.vue'
          ),
      },

      {
        path: 'category/:category',
        name: 'client-showcase-category',
        component: () =>
          import(
            /* webpackChunkName: "showcase-category" */
            '@frontend/pages/showcase/common/Category.vue'
          ),
      },

      {
        path: 'category/:category/:product',
        name: 'client-showcase-product',
        component: () =>
          import(
            /* webpackChunkName: "showcase-product" */
            '@frontend/pages/showcase/common/Product.vue'
          ),
      },

      {
        path: 'collections/:collectionId(\\d+)',
        name: 'client-showcase-collection',
        component: () =>
          import(
            /* webpackChunkName: "showcase-collection" */
            '@frontend/pages/showcase/common/Collection.vue'
          ),
      },

      {
        path: 'cart',
        name: 'client-showcase-cart',
        component: () =>
          import(
            /* webpackChunkName: "showcase-cart" */
            '@frontend/pages/showcase/common/Cart.vue'
          ),
      },

      {
        path: 'purchases/:purchaseId(\\d+)',
        name: 'client-showcase-the-purchase',
        component: () =>
          import(
            /* webpackChunkName: "client-showcase-purchase" */
            '@frontend/pages/showcase/client/Purchase.vue'
          ),
      },

      {
        path: 'purchases-ad/:purchaseId(\\d+)',
        name: 'client-showcase-the-purchase-ad',
        component: () =>
          import(
            /* webpackChunkName: "client-showcase-purchase-ad" */
            '@frontend/pages/showcase/client/PurchaseAd.vue'
          ),
      },

      {
        path: 'purchases/:purchaseId(\\d+)/items/:purchaseItemId(\\d+)',
        name: 'client-showcase-product-purchased',
        component: () =>
          import(
            /* webpackChunkName: "client-showcase-product-purchased" */
            '@frontend/pages/showcase/common/ProductPurchased.vue'
          ),
        children: [
          {
            path: 'materials',
            name: 'client-showcase-product-purchased-materials',
            component: () =>
              import(
                /* webpackChunkName: "client-showcase-product-purchased-materials" */
                '@frontend/pages/showcase/common/ProductMaterials.vue'
              ),
          },
        ],
      },

      {
        path: '/cart/:offerId(\\d+)',
        name: 'client-showcase-product-link',
        component: () =>
          import(
            /* webpackChunkName: "showcase-product-link" */
            '@frontend/pages/showcase/common/ProductLink.vue'
          ),
      },

      {
        path: '/client/product-access-link/category/:category/:product',
        name: 'client-showcase-product-access-link',
        component: () =>
          import(
            /* webpackChunkName: "showcase-product-access-link" */
            '@frontend/pages/showcase/common/Product.vue'
          ),
      },
    ],
  },
]
