<template>
  <component :is="componentItem" class="home__subject" :to="subjectDetailUrl">
    <div v-if="educationType.fullTime" class="form-row">
      <div class="subject__name col-8">
        <div>
          {{ name }}
          <span v-if="subtitle" class="t-tag">{{ subtitle }}</span>

          <span v-if="hasTrainingPrograms" class="t-tag">
            {{ trainingPrograms }}
          </span>
        </div>

        <div v-if="isVerified">
          <img
            class="subject__icon-educont-verified"
            :src="iconEducontVerified"
            alt="educont-verified"
          />
        </div>

        <div v-if="isVerified">
          <img
            class="subject__icon-educont-verified"
            :src="iconEducontVerified"
            alt="educont-verified"
          />
        </div>

        <!-- TODO: i18n -->
        <div
          v-if="isSuspended"
          v-tippy="'Необходимо оплатить доступ'"
          class="ml-2"
        >
          <i class="fa fa-lock"></i>
        </div>
      </div>

      <div class="subject__name col-4 text-right">{{ mark }}</div>
    </div>

    <div v-else class="subject__name">
      <div class="d-flex align-items-center justify-content-between">
        <div class="flex-1">
          {{ name }}
          <span v-if="subtitle" class="t-tag">{{ subtitle }}</span>

          <span v-if="hasTrainingPrograms" class="t-tag">
            {{ trainingPrograms }}
          </span>
        </div>

        <div v-if="isVerified">
          <img
            class="subject__icon-educont-verified"
            :src="iconEducontVerified"
            alt="educont-verified"
          />
        </div>

        <!-- TODO: i18n -->
        <div
          v-if="isSuspended"
          v-tippy="'Необходимо оплатить доступ'"
          class="ml-2"
        >
          <i class="fa fa-lock"></i>
        </div>
      </div>

      <div v-if="isAdditional" class="t-tag">{{ grade }}</div>
    </div>

    <ProgressBar
      v-if="!educationType.fullTime"
      :color="color"
      :progress="progress"
      :checkpoint="checkpoint"
    />

    <div v-if="educationType.family" class="home__subject-stat row">
      <div class="col-3">
        <span class="t-tag">
          {{ $t('components.subjects.topic') }}
        </span>

        {{ topicsProgress }}
      </div>

      <div class="col-4">
        <span class="t-tag">
          {{ $t('components.subjects.development') }}
        </span>

        {{ miniTestsProgress }}
      </div>
    </div>
  </component>
</template>

<script>
import ProgressBar from '@frontend/ui/ProgressBar.vue'
import iconEducontVerified from 'Static/images/@frontend/common-lk/icons/educont-verified.svg'
import { mapGetters } from 'vuex'

export default {
  name: 'SubjectItemPreview',

  components: {
    ProgressBar,
  },

  props: {
    subject: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      iconEducontVerified,
    }
  },

  computed: {
    ...mapGetters('student/profile', [
      'educationType',
      'isSchoolEducont',
    ]),

    subjectDetailUrl() {
      return {
        name: 'subject',
        params: {
          subjectId: this.subject.id,
          groupId: this.subject.group.id,
        },
      }
    },

    /**
     * @returns {number}
     */
    progress() {
      return parseInt((this.ctpLessonsPassed / this.ctpLessonsTotal) * 100, 10)
    },

    /**
     * @returns {number}
     */
    checkpoint() {
      return parseInt((this.ctpLessonsOver / this.ctpLessonsTotal) * 100, 10)
    },

    /**
     * @returns {string}
     */
    color() {
      return this.subject.color
    },

    /**
     * @returns {boolean}
     */
    isAdditional() {
      return Boolean(this.subject.isAdditional)
    },

    /**
     * @returns {boolean}
     */
    hasTrainingPrograms() {
      return this.subject.trainingPrograms?.length > 0
    },

    /**
     * @returns {boolean}
     */
    trainingPrograms() {
      return this.subject.trainingPrograms.map((item) => item.name).join(', ')
    },

    /**
     * @returns {string}
     */
    name() {
      return this.subject.name
    },

    /**
     * @returns {string}
     */
    grade() {
      return `(${this.subject.group.gradeId} ${this.$t(
        'pages.subjects.grade_lc',
      )})`
    },

    /**
     * @returns {string}
     */
    subtitle() {
      return this.subject.subtitle
    },

    /**
     * @returns {number}
     */
    ctpLessonsPassed() {
      return Number(this.subject.ctpLessonsPassed)
    },

    /**
     * @returns {number}
     */
    ctpLessonsOver() {
      return Number(this.subject.ctpLessonsOver)
    },

    /**
     * @returns {number}
     */
    ctpLessonsTotal() {
      return Number(this.subject.ctpLessonsTotal)
    },

    mark() {
      const hasTilda = this.subject.currentPeriodMarkShowTilda
      let result = ''

      if (this.subject.currentPeriodMark) {
        if (hasTilda) {
          result = '~'
        }

        result += this.subject.currentPeriodMark
      } else {
        result = '—'
      }

      return result
    },

    /**
     * @returns {string}
     */
    miniTestsProgress() {
      const progress = parseInt(
        (this.subject.ctpMiniTestsCompleted / this.subject.ctpMiniTestsTotal) *
          100,
        10,
      )

      return `${progress || 0}%`
    },

    /**
     * @returns {string}
     */
    topicsProgress() {
      const progress = parseInt(
        (this.subject.ctpTopicsCompleted / this.subject.ctpTopicsTotal) * 100,
        10,
      )

      return `${progress || 0}%`
    },

    isVerified() {
      return this.isSchoolEducont && this.subject.ctp.educontVerified
    },

    isSuspended() {
      return this.subject.isSuspended
    },

    componentItem() {
      return this.isSuspended ? 'div' : 'router-link'
    },
  },
}
</script>

<style lang="scss">
@import '~@frontend/scss/variables';

.home {
  &__subject {
    display: block;
    margin-bottom: 16px;
    color: $black;

    &:hover {
      color: inherit;
      opacity: 0.48;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-stat {
      position: relative;
      padding-bottom: 16px;
      margin-top: 8px;
      font-weight: 500;
    }

    .subject__name {
      margin-bottom: 8px;
    }

    .note {
      display: inline;
      font-size: 26px;
      line-height: 18px;
      color: $secondary;
      vertical-align: middle;
    }
  }
}
</style>
