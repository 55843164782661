import actions from '@frontend/store/courses/common/actions'
import cart from '@frontend/store/courses/common/cart/store'
import catalog from '@frontend/store/courses/common/catalog/store'
import collection from '@frontend/store/courses/common/collection/store'
import getters from '@frontend/store/courses/common/getters'
import home from '@frontend/store/courses/common/home/store'
import mutations from '@frontend/store/courses/common/mutations'
import product from '@frontend/store/courses/common/product/store'
import purchase from '@frontend/store/courses/common/purchase/store'
import state from '@frontend/store/courses/common/state'

export default {
  namespaced: true,

  modules: {
    cart,
    catalog,
    collection,
    home,
    product,
    purchase,
  },

  state,
  getters,
  mutations,
  actions,
}
