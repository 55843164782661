import { i18n } from '@/i18n/setup'
import snapshotTesting from '@frontend/helpers/snapshotTesting'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import { ApiTimestamp } from 'Api/system/Timestamp'
import { ApiAccountStudentDiagnosticCtpTest } from 'ApiRest/Api/Account/Student/Diagnostics/Test/CtpTests'
import { ApiAccountStudentDiagnosticCtpTestings } from 'ApiRest/Api/Account/Student/Diagnostics/Test/TestingList'
import { ApiStudentQuestionReset } from 'ApiRest/Api/Account/Student/Question/Reset'
import { ApiStudentQuestionTest } from 'ApiRest/Api/Account/Student/Question/Test'
import ApiAccountStudentTestAnswerList from 'ApiRest/Api/Account/Student/Test/Answer/List'
import ApiAccountStudentTestAnswerListActive from 'ApiRest/Api/Account/Student/Test/Answer/ListActive'
import ApiAccountStudentTestAnswerOnCheck from 'ApiRest/Api/Account/Student/Test/Answer/OnCheck'
import ApiAccountStudentTestAnswerRetry from 'ApiRest/Api/Account/Student/Test/Answer/Retry'
import ApiAskQuestion from 'ApiRest/Api/Account/Student/Test/AskQuestion'
import ApiAccountStudentTestAttempt from 'ApiRest/Api/Account/Student/Test/Attempt'
import ApiAccountStudentTestAttemptList from 'ApiRest/Api/Account/Student/Test/Attempt/List'
import ApiAccountStudentTestAttemptStarted from 'ApiRest/Api/Account/Student/Test/Attempt/Started'
import ApiAccountStudentCtpTestTestingCreate from 'ApiRest/Api/Account/Student/Test/CtpTest/TestingCreate'
import ApiAccountStudentTestTestingOnCheck from 'ApiRest/Api/Account/Student/Test/Testing/OnCheck'
import ApiAccountStudentTestTestingReturnByStudent from 'ApiRest/Api/Account/Student/Test/Testing/ReturnByStudent'
import Constants from 'Constants'
import { showToast } from 'Helpers/toast'
import { maxBy } from 'lodash'

export default {
  /**
   * Получение вопроса для прохождения учеником
   * @param {Object} context
   * @param {{ context: Object, questionId: number }} payload
   * @returns {Promise<Object>}
   */
  async fetchQuestionTest(context, payload) {
    const response = await ApiStudentQuestionTest.get(payload)

    return response
  },

  /**
   * Получение КТП-Теста по ID
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} ctpTestId - ID КТП-Теста
   * @returns {Promise<void>}
   */
  async fetchCtpTest({ commit }, ctpTestId) {
    const { data } = await ApiAccountStudentDiagnosticCtpTest.get(ctpTestId)

    commit('setTest', data)
    commit('setTestPointsMarks')
  },

  /**
   * Получение списка Прохождения Теста у КТП-Теста
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} ctpTestId - ID КТП-Теста
   * @returns {Promise<void>}
   */
  async fetchCtpTestingList({ commit }, ctpTestId) {
    const { data } = await ApiAccountStudentDiagnosticCtpTestings.get(ctpTestId)

    commit('setTestingList', data)
  },

  /**
   * Перевести Прохождение Теста в статус "На проверке" (завершить прохождение)
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @returns {Promise<void>}
   */
  async testingSendOnCheck({ state, commit, dispatch }) {
    const { data } = await ApiAccountStudentTestTestingOnCheck.put(
      state.testing.id,
    )

    commit('setTesting', data)

    await dispatch('fetchAnswerList', state.attempt.id)
    await dispatch('fetchAttempt', state.attempt.id)

    snapshotTesting.clearSnapshot(state.attempt.id)
  },

  /**
   * Запустить Прохождение
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @returns {Promise<void>}
   */
  startTesting: async ({ state, commit, dispatch }) => {
    await dispatch('fetchAttemptList', state.testing.id)

    const testingCurrentAttempt = state.attemptList.find(
      (attempt) => attempt.isCurrent,
    )

    commit('setAttempt', testingCurrentAttempt)

    await dispatch('startAttempt', state.attempt.id)
    await dispatch('fetchAnswerListActive')

    const testingTime = state.test.testingTime * 60 * 1000

    commit('setEstimatedTime', testingTime)
  },

  /**
   * Запустить Прохождение КТП-Теста (начать прохождение)
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @returns {Promise<void>}
   */
  async startCtpTesting({ state, commit, dispatch }) {
    const { data } = await ApiAccountStudentCtpTestTestingCreate.post(
      state.test.id,
    )

    commit('setTesting', data)

    await dispatch('startTesting')
  },

  /**
   * Получить список Попыток для указанного Прохождения Теста
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} testingId - ID Прохождение Теста
   * @returns {Promise<void>}
   */
  async fetchAttemptList({ commit }, testingId) {
    const { data } = await ApiAccountStudentTestAttemptList.get(testingId)

    commit('setAttemptList', data)
  },

  /**
   * Получить Попытку по ID
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} attemptId - ID Попытки
   * @returns {Promise<void>}
   */
  async fetchAttempt({ commit }, attemptId) {
    const { data } = await ApiAccountStudentTestAttempt.get(attemptId)

    commit('setAttempt', data)
  },

  /**
   * Начать Попытку Прохождения Теста
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @returns {Promise<void>}
   */
  async startAttempt({ state, commit }) {
    const { data, status } = await ApiAccountStudentTestAttemptStarted.put(
      state.attempt.id,
    )

    if (status === HttpStatusCodes.Ok) {
      commit('setAttempt', data)
    }
  },

  /**
   * Получить список вопросов
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @returns {Promise<void>}
   */
  async fetchAnswerList({ state, commit }) {
    const { data } = await ApiAccountStudentTestAnswerList.get(state.attempt.id)

    commit('setAnswerList', data)
  },

  /**
   * Получить список вопросов
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @returns {Promise<boolean>}
   */
  async fetchAnswerListActive({ state, commit }) {
    const { data: questions } = await ApiAccountStudentTestAnswerListActive.get(
      state.attempt.id,
    )

    // TODO: начало костыля для получения отвеченных вопросов
    const compareResult = await snapshotTesting.questionsCompareWithSnapshot({
      attemptId: state.attempt.id,
      questions,
    })

    // если есть недополученные вопросы или снэпшота на локале нет
    if (compareResult !== true && !!compareResult?.length) {
      const compareResultIds = compareResult.map(({ id }) => id)

      const { data: questionsMissed } =
        await ApiAccountStudentTestAnswerList.get(state.attempt.id)

      const missedQuestions = questionsMissed.filter(({ id }) =>
        compareResultIds.includes(id),
      )

      const result = [
        ...missedQuestions,
        ...questions,
      ].sort((a, b) => a.id - b.id)

      commit('setAnswerList', result)

      return true
    }

    // TODO: конец костыля

    commit('setAnswerList', questions)

    return true
  },

  /**
   * Получение вопроса для прохождения учеником
   * @param {Object} context
   * @param {{ context: Object, questionId: number }} payload
   * @returns {Promise<Object>}
   */
  takeTheQuestionTestAgain(context, payload) {
    return ApiStudentQuestionReset.put(payload)
  },

  /**
   * Перевести Ответ в статус "На проверке"
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {Object} payload.answer
   * @param {number} payload.answerId
   * @returns {Promise<void>}
   */
  async answerSendOnCheck({ commit }, { answerId, answer }) {
    const { data } = await ApiAccountStudentTestAnswerOnCheck.put(
      answerId,
      answer,
    )

    commit('setAnswer', data)
    commit('updateAnswerList')
  },

  /**
   * Сбросить Ответ
   * @param {Object} context
   * @param {number} answerId
   * @returns {Promise<void>}
   */
  retryAnswer(context, answerId) {
    return ApiAccountStudentTestAnswerRetry.put(answerId)
  },

  /**
   * Определяет доступность теста к прохождению
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @param {number} attemptId
   * @returns {Promise<boolean>}
   */
  async prepareForTesting({ state, commit, dispatch }, attemptId) {
    // Прохождение на проверке
    const testingOnCheck = state.testingList.find(
      (testing) => Constants.testingStatus.ON_CHECK === testing.status.id,
    )

    // Прохождение завершено
    const testingCompleted = state.testingList.find(
      (testing) => Constants.testingStatus.COMPLETED === testing.status.id,
    )

    const testingReturnedByStudent = state.testingList.find(
      (testing) =>
        testing.status.id === Constants.testingStatus.RETURNED_BY_STUDENT,
    )

    if (testingOnCheck) {
      commit('setTesting', testingOnCheck)
    }

    if (testingCompleted) {
      commit('setTesting', testingCompleted)
    }

    if (testingReturnedByStudent) {
      commit('setTesting', testingReturnedByStudent)
    }

    if (testingCompleted || testingOnCheck || testingReturnedByStudent) {
      await dispatch('fetchAttemptList', state.testing.id)
      // Текущая попытка прохождения
      let testingCurrentAttempt

      if (attemptId) {
        testingCurrentAttempt = state.attemptList.find(
          (attempt) => attempt.id === attemptId,
        )
      } else {
        testingCurrentAttempt = state.attemptList.find(
          (attempt) => attempt.isCurrent,
        )
      }

      commit('setAttempt', testingCurrentAttempt)

      await dispatch('fetchAnswerList', state.attempt.id)

      return false
    }

    //  ктп-тест доступен по времени
    const isAvailableByDateTime = state.test.isAvailable

    // Допустимые для продолжения прохождения статусы
    const canContinueStatusesList = [
      Constants.testingStatus.IN_PROCESS,
    ]

    // Есть ли в списке прохождений, доступное для продолжения
    const containsCanContinueStatus = state.testingList.some((testing) =>
      canContinueStatusesList.includes(testing.status.id),
    )

    if (isAvailableByDateTime && containsCanContinueStatus) {
      // Текущие прохождения (может быть несколько)
      const currentTestings = state.testingList.filter((testing) =>
        canContinueStatusesList.includes(testing.status.id),
      )

      // Текущее прохождение (последнее по ID)
      const currentTesting = maxBy(currentTestings, 'id')

      commit('setTesting', currentTesting)

      await dispatch('fetchAttemptList', state.testing.id)
      // Текущая попытка прохождения
      const testingCurrentAttempt = state.attemptList.find(
        (attempt) => attempt.isCurrent,
      )

      commit('setAttempt', testingCurrentAttempt)

      // Попытка прохождения начата
      const attemptStarted =
        state.attempt.status.id === Constants.testingAttemptStatus.STARTED
      // Попытка прохождения НЕ начата
      const attemptNotStarted =
        state.attempt.status.id === Constants.testingAttemptStatus.NOT_STARTED

      if (attemptStarted) {
        // Время на выполнение теста, в мс
        const testingTime = state.test.testingTime * 60 * 1000

        // Дата начала прохождения теста, в мс
        const startedAt = state.attempt.startedAt * 1000

        // Текущее серверное время, в сек
        const { data: currentTime } = await ApiTimestamp.get()

        // Оставшееся время у активной попытки со статусом "Начата"
        const estimatedTime = testingTime - (currentTime * 1000 - startedAt)

        // Если время не вышло, то попытку можно продолжить
        if (estimatedTime > 0) {
          commit('setEstimatedTime', estimatedTime)
          commit('setTestingMeta', {
            key: 'testingCanContinue',
            value: true,
          })
          await dispatch('fetchAnswerListActive')
          commit('setAnswerListMeta')
        } else {
          // Если время вышло, принудительно отправляем на проверку
          await dispatch('testingSendOnCheck')
        }
      } else if (attemptNotStarted) {
        // Если попытка активная, но статус "Не начата", стартуем попытку
        commit('setTestingMeta', {
          key: 'testingCanStart',
          value: true,
        })
      }

      return false
    }

    // Допустимые для начала прохождения статусы
    const canStartStatusesList = [
      Constants.testingStatus.RETURNED_QUESTIONS,
      Constants.testingStatus.ANNULLED,
      Constants.testingStatus.RETURNED_ANOTHER_VARIANT,
    ]

    // Нет прохождений ктп-теста
    const hasNoTestings = state.testingList.length === 0

    // Есть ли в списке прохождений, доступное для начала
    const containsCanStartStatus = state.testingList.some((testing) =>
      canStartStatusesList.includes(testing.status.id),
    )

    if (isAvailableByDateTime && (hasNoTestings || containsCanStartStatus)) {
      commit('setTestingMeta', {
        key: 'testingCanStart',
        value: true,
      })
    }

    if (containsCanStartStatus) {
      const lastTesting = state.testingList[state.testingList.length - 1]

      commit('setTesting', lastTesting)
    }

    return false
  },

  async sendMessageForTeacher({ state, commit }, payload) {
    const response = await ApiAccountStudentTestTestingReturnByStudent.put(
      state.testing.id,
      payload,
    )

    commit('setTesting', response.data)
  },

  /**
   * Отправка замечания по вопросу
   * @param {Object} context
   * @param {Object} payload
   * @param {number} payload.questionId
   * @param {string} payload.questionBody
   * @param {number} [payload.testId]
   * @param {number} [payload.ctpId]
   * @param {number} [payload.certId]
   */
  async sendReport(context, payload) {
    const data = {
      question: {
        id: payload.questionId,
      },
      test: {
        id: payload.testId,
      },
      questionBody: payload.questionBody,
    }

    if (payload.ctpId) {
      data.ctp = {
        id: payload.ctpId,
      }
    }

    if (payload.certId) {
      data.cert = {
        id: payload.certId,
      }
    }

    await ApiAskQuestion.post(payload.questionId, data)
      .then(() => {
        showToast(
          i18n.t('store.student.toasted_message.message_has_been_sent'),
          'success',
        )
      })
      .catch((error) => {
        showToast(
          i18n.t('store.student.toasted_message.error_has_occurred'),
          'error',
        )

        throw error
      })
  },

  /**
   * Сохраняет все вопросы из меты в localStorage (весь массив заменяется)
   * @param {Object} context
   * @param {Object} context.state
   * @param {number} attemptId
   */
  updateSnapshot({ state }, attemptId) {
    const questions = state.answerListMeta.map((answer) => ({
      status: answer.status,
      content: answer.content,
      id: answer.id,
    }))

    // Дата начала прохождения теста, в сек
    const timeCreated = state.attempt.startedAt

    snapshotTesting.questionsSet({
      attemptId,
      questions,
      timeCreated,
    })
  },

  /**
   * Заполняет ответы вопросов из сохраненного снэпшота в локалсторадже
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {number} attemptId
   */
  async fillAnswersFromSnapshot({ state, commit }, attemptId) {
    const questions = await snapshotTesting.questionsGet(attemptId)

    if (questions) {
      questions.forEach((question) => {
        if (question.content) {
          const questionId = question.id
          const index = state.answerListMeta.findIndex(
            (answer) => answer.id === questionId,
          )

          if (index > -1) {
            commit('setAnswerMeta', {
              index,
              key: 'content',
              value: question.content,
            })
          }
        }
      })
    }
  },
}
