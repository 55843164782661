export const clientPaymentRoutes = [
  {
    path: 'payment',
    name: 'client-showcase-payment',
    component: () =>
      import(
        /* webpackChunkName: "client-showcase-payment" */
        '@frontend/pages/showcase/client/Payments.vue'
      ),
    children: [
      {
        path: 'new-invoices',
        name: 'client-showcase-payment-new-invoices',
        component: () =>
          import(
            /* webpackChunkName: "client-showcase-payment-invoices" */
            '@frontend/pages/showcase/client/Invoices.vue'
          ),
        props: {
          isNewInvoices: true,
        },
      },
      {
        path: 'invoices',
        name: 'client-showcase-payment-invoices',
        component: () =>
          import(
            /* webpackChunkName: "client-showcase-payment-invoices" */
            '@frontend/pages/showcase/client/Invoices.vue'
          ),
      },
      {
        path: 'subscriptions',
        name: 'client-showcase-payment-subscriptions',
        component: () =>
          import(
            /* webpackChunkName: "client-showcase-payment-subscriptions" */
            '@frontend/pages/showcase/client/Subscriptions.vue'
          ),
      },
    ],
  },
  {
    path: 'payment/invoices/:invoiceId(\\d+)',
    name: 'client-showcase-payment-the-invoice',
    component: () =>
      import(
        /* webpackChunkName: "client-showcase-payment-the-invoice" */
        '@frontend/pages/showcase/client/Invoice.vue'
      ),
    props: true,
  },
]
