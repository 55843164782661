export const clientSupportRoutes = [
  {
    path: 'support',
    name: 'client-support',
    component: () =>
      import(
        /* webpackChunkName: "support" */
        '@frontend/pages/showcase/common/support/Support.vue'
      ),

    children: [
      {
        path: 'questions',
        name: 'client-support-questions',
        component: () =>
          import(
            /* webpackChunkName: "support-questions" */
            '@frontend/pages/showcase/common/support/Questions.vue'
          ),
      },
    ],
  },

  {
    path: 'support/questions/:questionId(\\d+)',
    name: 'client-support-the-question',
    component: () =>
      import(
        /* webpackChunkName: "support-the-question" */
        '@frontend/pages/showcase/common/support/TheQuestion.vue'
      ),
  },
]
