<template>
  <div :class="cssClassCard" @click="onClickCard">
    <div>
      <img
        :src="image.src"
        :class="$style.cardImage"
        loading="lazy"
        alt="cover"
      />
    </div>

    <div class="ml-3 py-2">
      <h4 class="h4 mb-2">{{ product.name }}</h4>

      <p v-if="dateInfoText" class="text-gray font-size-12 mb-2">
        {{ dateInfoText }}
      </p>

      <strong class="h3 mb-0" :class="$style.cardPrice">{{ priceText }}</strong>
    </div>

    <div :class="$style.cardCheck">
      <img :src="icons.check" alt="icon check" width="12" />
    </div>
  </div>
</template>

<script>
import { getProductDateInfoText } from '@frontend/helpers/product/getProductDateInfoText'
import { getProductImage } from '@frontend/helpers/product/getProductImage'
import iconCheck from 'Static/images/components/uiKit/checkbox/iconActive.svg'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductCard',

  props: {
    product: {
      type: Object,
      required: true,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      icons: {
        check: iconCheck,
      },
    }
  },

  computed: {
    cssClassCard() {
      return [
        this.$style.card,
        {
          [this.$style.isSelected]: this.isSelected,
        },
      ]
    },

    image() {
      return getProductImage(this.product)
    },

    price() {
      return this.product.offer.price ?? null
    },

    // TODO: i18n
    dateInfoText() {
      return getProductDateInfoText(this.product)
    },

    priceText() {
      return this.price ? `${this.price} ₽` : 'Бесплатно'
    },
  },

  methods: {
    onClickCard() {
      this.$emit('click')
    },
  },
})
</script>

<style lang="scss" module>
$gray: #dedee0;
$blue: #1458e5;
$blue-1: #1458e580;

.card {
  position: relative;
  padding: 20px;
  border: 2px solid $gray;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  &:hover {
    border-color: $blue-1;

    .cardCheck {
      background: $blue-1;
    }
  }

  &.isSelected {
    border-color: $blue;

    .cardCheck {
      background-color: $blue;
    }
  }
}

.cardCheck {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  border-radius: 9999px;
  background-color: $gray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardImage {
  display: block;
  width: 120px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.cardPrice {
  font-weight: 500;
}
</style>
