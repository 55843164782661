<template>
  <ElModal
    :name="name"
    :width="600"
    :adaptive="true"
    transition="none"
    class="modal-content"
  >
    <div class="vm--modal-content">
      <ElButton class="btn-img vm--modal-close" @click="onClickBtnClose">
        <img :src="icons.close" alt="icon close" />
      </ElButton>

      <div>
        <!-- TODO: i18n -->
        <h3 class="h3 text-dark">
          Для перехода в личный кабинет клиента введите пароль пользователя
          <a :href="hrefClientEmail">{{ profile.clientEmail }}</a>
        </h3>

        <form @submit.prevent="onSubmitForm">
          <ElInput
            :value="profile.clientEmail"
            autocomplete="username"
            class="d-none"
          />

          <ElInputPassword
            v-model="formClientPassword"
            :invalid="v$.formClientPassword.$error"
            :errors="v$.formClientPassword.$errors"
            required
            autocomplete="current-password"
            autofocus
          />

          <div class="d-flex justify-content-between">
            <!-- TODO: i18n -->
            <ElButton
              :is-loading="isLoading.btnGo"
              type="submit"
              label="Перейти"
              class="btn-primary"
            />

            <!-- TODO: i18n -->
            <ElButton
              label="Отменить"
              class="btn-danger"
              @click="onClickBtnCancel"
            />
          </div>
        </form>
      </div>
    </div>
  </ElModal>
</template>

<script>
import ElButton from '@frontend/ui/ElButton.vue'
import ElInput from '@frontend/ui/ElInput.vue'
import ElInputPassword from '@frontend/ui/ElInputPassword.vue'
import ElModal from '@frontend/ui/ElModal.vue'
import { authUrl } from 'Helpers/const/authUrl'
import { showErrors, showToast } from 'Helpers/toast'
import iconClose from 'Static/images/@frontend/common-lk/icons/close.svg'
import { required } from 'Helpers/i18n-validators'
import { mapActions, mapMutations, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { defineComponent } from 'vue'
import { checkForErrorsMessage } from 'Helpers/ValidationHelper'

export default defineComponent({
  name: 'ModalClientPassword',

  components: {
    ElInput,
    ElModal,
    ElButton,
    ElInputPassword,
  },

  setup() {
    return {
      v$: useVuelidate({
        $stopPropagation: true,
        $autoDirty: true,
      }),
    }
  },

  validations() {
    return {
      formClientPassword: {
        required,
      },
    }
  },

  data() {
    return {
      name: 'modalClientPassword',

      icons: {
        close: iconClose,
      },

      isLoading: {
        btnGo: false,
      },
    }
  },

  computed: {
    ...mapState('student/profile', [
      'profile',
      'clientPassword',
      'clientToken',
    ]),

    hrefClientEmail() {
      return `mailto: ${this.profile.clientEmail}`
    },

    hrefClient() {
      const url = new URL(authUrl.userSwitch)

      if (this.clientToken) {
        url.searchParams.append('token', this.clientToken)
      }

      return url.href
    },

    formClientPassword: {
      get() {
        return this.clientPassword
      },

      set(value) {
        this.setClientPassword(value)
      },
    },
  },

  methods: {
    ...mapMutations('student/profile', [
      'setClientPassword',
    ]),

    ...mapActions('student/profile', [
      'fetchClientToken',
    ]),

    async switchToClient() {
      try {
        await this.fetchClientToken()

        window.location.href = this.hrefClient
      } catch (error) {
        showErrors(error)
      }
    },

    async onSubmitForm() {
      if (!(await this.v$.$validate())) {
        showToast(checkForErrorsMessage, 'error')

        return false
      }

      this.isLoading.btnGo = true
      await this.switchToClient()
      this.isLoading.btnGo = false

      return true
    },

    onClickBtnClose() {
      this.hide()
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     */
    show() {
      this.$modal.show(this.name)
    },

    /**
     * @public
     */
    hide() {
      this.$modal.hide(this.name)
    },
  },
})
</script>
