import { i18n } from '@/i18n/setup'
import { RouterUser } from '@frontend/RouterUser'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiLesson from 'ApiRest/Api/Account/Client/Lesson'
import ApiLessonList from 'ApiRest/Api/Account/Client/Lesson/List'
import { showToast } from 'Helpers/toast'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchLessons({ commit }, studentId) {
    const { data } = await ApiLessonList.get(studentId)

    commit('setLessons', data)
  },

  async fetchTheLesson({ commit, getters }, id) {
    const lesson = getters.getCurrentLesson(id)

    if (!lesson) {
      try {
        const { data } = await ApiLesson.get(id)

        commit('setTheLesson', data)
      } catch (error) {
        // TODO: -> component
        if (error?.response?.status === HttpStatusCodes.NotFound) {
          showToast(
            i18n.t('store.client.toasted_message.lesson_was_not_found'),
            'error',
          )

          RouterUser.router.push({
            name: 'not-found',
          })
        }
      }
    }
  },
}
