import Vue from 'vue'

const createOfferDoc = (offerDoc) => ({
  ...offerDoc,
  blob: '',
  isChecked: false,
})

export default {
  setInvoices(state, payload) {
    state.invoices = payload
  },

  setTheInvoice(state, payload) {
    state.invoice = payload
  },

  setTheInvoicePaymentUrl(state, payload) {
    state.paymentUrl = payload
  },

  setHasFamilyInvoice(state, payload) {
    state.hasFamilyInvoice = payload
  },

  setDiagnosticsOfferDoc(state, payload) {
    state.diagnosticsOfferDoc = payload
  },

  setOfferDocs(state, payload = []) {
    state.offerDocs = payload.map((offerDoc) => createOfferDoc(offerDoc))
  },

  setOfferDocChecked(state, { offerDoc, isChecked }) {
    const offerDocFound = state.offerDocs.find((doc) => doc.id === offerDoc.id)

    if (offerDocFound) {
      offerDocFound.isChecked = isChecked
    }
  },

  setOfferDocBlob(state, { id, payload }) {
    const doc = state.offerDocs.find((offerDoc) => offerDoc.id === id)

    Vue.set(doc, 'blob', payload)
  },

  setRecurrentPaymentsDocBlob(state, { id, payload }) {
    const doc = state.invoice.recurrentPaymentsDocuments.find(
      (document) => document.id === id,
    )

    Vue.set(doc, 'blob', payload)
  },
}
