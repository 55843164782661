import { i18n } from '@/i18n/setup'
import { RouterUser } from '@frontend/RouterUser'
import HttpHeaders from 'Api/const/HttpHeaders'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiEvent from 'Api/rest/Api/Account/Student/Event'
import ApiEventList from 'Api/rest/Api/Account/Student/Event/List'
import { showToast } from 'Helpers/toast'

export default {
  async fetchEvents({ commit }, payload) {
    const { headers, data } = await ApiEventList.get(payload)

    if (payload?.page === 1) {
      commit('setEvents', data)
    } else {
      commit('addEvents', data)
    }

    const pagination = {
      currentPage: headers[HttpHeaders.PAGINATION_PAGE],
      pagesTotal: headers[HttpHeaders.PAGINATION_PAGES_TOTAL],
    }

    commit('setPagination', pagination)
  },

  async fetchTheEvent({ commit }, id) {
    try {
      const { data } = await ApiEvent.get(id)

      commit('setTheEvent', data)
    } catch (error) {
      // TODO: -> component
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        showToast(
          i18n.t('store.student.toasted_message.event_not_found'),
          'error',
        )

        await RouterUser.router.push({
          name: 'student-main',
        })
      }

      throw error
    }
  },
}
