import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiShowcaseProductsByIds = new (class extends ApiBase {
  get(params = {}) {
    const urlParams = UrlHelper.param(params)
    const url = `/showcase/products-by-ids?${urlParams}`

    return this._GET(url)
  }
})()
