import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export default new (class extends ApiBase {
  /**
   * Ведомость по ученику очной формы обучения
   * @param {number} studentId - ID студента
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(studentId, parameters = {}) {
    return this._GET(
      `/account/client/students/${studentId}/full-time-ctp-statement?${UrlHelper.param(
        parameters,
      )}`,
    )
  }
})()
