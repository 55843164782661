<template>
  <modal
    v-bind="$attrs"
    v-on="$listeners"
    @before-open="modalBeforeOpen"
    @before-close="modalBeforeClose"
  >
    <slot></slot>
  </modal>
</template>

<script>
export default {
  name: 'ElModal',

  inheritAttrs: false,

  beforeDestroy() {
    this.removeClass()
  },

  methods: {
    modalBeforeOpen() {
      document.body.classList.add('modal-open')
    },

    removeClass() {
      document.body.classList.remove('modal-open')
    },

    modalBeforeClose() {
      this.removeClass()
    },
  },
}
</script>
