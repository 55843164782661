import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setStatements(state, payload) {
    state.statements = payload
  },

  setAcademicYears(state, payload) {
    state.academicYears = payload
  },

  setAcademicYear(state, payload) {
    state.academicYear = payload
  },

  setStatementExtramuralPromoCtps(state, payload) {
    const firstStatement = state.statements.extramural[0]
    const promoCtps = payload.map((item) => ({
      ...item,
      isActual: true,
      isPromo: true,
    }))

    Vue.set(state.statements.extramural, 0, {
      ...firstStatement,
      items: [
        ...firstStatement.items,
        ...promoCtps,
      ],
    })
  },

  setStatementIntramuralItemsFilter(state, payload) {
    state.statementIntramural.itemsFilter = payload
  },
}
