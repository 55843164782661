/**
 * @returns {Object}
 */
export default () => ({
  links: [
    {
      link: {
        name: 'client-showcase',
      },
      // TODO: i18n
      labelPath: 'Главная',
      class: 'm-showcase',
      notifications: 0,
    },
    {
      link: {
        name: 'client-showcase-education',
      },
      // TODO: i18n
      labelPath: 'Моё обучение',
      class: 'm-subjects',
      notifications: 0,
      children: [
        {
          link: {
            name: 'client-showcase-subjects',
          },
          // TODO: i18n
          labelPath: 'Обучение',
        },
        {
          link: {
            name: 'client-showcase-payment',
          },
          // TODO: i18n
          labelPath: 'Платежи',
        },
        {
          link: {
            name: 'client-showcase-purchases',
          },
          // TODO: i18n
          labelPath: 'Документы',
        },
      ],
    },
    {
      link: {
        name: 'client-showcase-category',
        params: {
          category: null,
        },
        query: null,
      },
      // TODO: i18n
      labelPath: 'Онлайн-школа',
      class: 'm-online-school',
      notifications: 0,
    },
    {
      link: {
        name: 'client-showcase-online-lessons',
      },
      // TODO: i18n
      labelPath: 'Расписание',
      class: 'm-online',
      notifications: 0,
    },
    {
      link: {
        name: 'client-notifications',
      },
      // TODO: i18n
      labelPath: 'Уведомления',
      class: 'm-notifications',
      notifications: 0,
    },
    {
      link: {
        name: 'client-support',
      },
      // TODO: i18n
      labelPath: 'Поддержка',
      class: 'm-help',
      notifications: 0,
    },
  ],
})
