import { decode } from '@admin/helpers/admin/userSettings/coding'
import { ApiShowcaseProducts } from 'ApiRest/Api/Showcase/Products'
import { ApiShowcaseProductsFilters } from 'ApiRest/Api/Showcase/Products/Filters'
import { ApiShowcaseProductsByCategory } from 'ApiRest/Api/Showcase/ProductsByCategory'
import { forIn } from 'lodash'

export default {
  applyQuery({ state, commit }, payload) {
    if (payload.settings) {
      let settings

      try {
        settings = JSON.parse(decode(payload.settings))
      } catch {
        settings = {}
      }

      const sort =
        state.sort.options.find((option) => option.value === settings.sort) ??
        state.sort.options[0]

      commit('setSort', sort)

      const { page, rowsPerPage } = settings

      commit('setPage', page)

      if (state.pagination.rowsPerPageOptions.includes(rowsPerPage)) {
        commit('setRowsPerPage', rowsPerPage)
      }

      commit('setSearch', settings.search ?? '')
      commit('setTag', settings.tag ?? '')

      forIn(settings.filters, (value, code) => {
        commit('setFilterValue', {
          code,
          value,
        })
      })
    }
  },

  async fetchFilters({ commit }, params) {
    const { data } = await ApiShowcaseProductsFilters.get(params)

    commit('setFilters', data)
  },

  async fetchProducts({ state, getters, commit, dispatch }) {
    const { data, headers } = await ApiShowcaseProducts.get(getters.settings)

    commit('setProducts', data)
    commit('updatePagination', headers)

    if (state.products.length === 0) {
      await dispatch('fetchProductsPopular')
    }
  },

  async fetchProductsByCategory({ state, getters, commit, dispatch }) {
    const { data, headers } = await ApiShowcaseProductsByCategory.get(
      state.categoryPrettyLink,
      getters.settings,
    )

    commit('setProducts', data)
    commit('updatePagination', headers)

    if (state.products.length === 0) {
      await dispatch('fetchProductsPopular')
    }
  },

  async fetchProductsPopular({ commit }) {
    const { data } = await ApiShowcaseProducts.get({
      sort: '-popularity',
      page: 1,
      rowsPerPage: 3,
    })

    commit('setProductsPopular', data)
  },
}
