import actions from '@frontend/store/courses/common/home/actions'
import getters from '@frontend/store/courses/common/home/getters'
import mutations from '@frontend/store/courses/common/home/mutations'
import state from '@frontend/store/courses/common/home/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
