import { i18n } from '@/i18n/setup'
import { eventBus } from '@/pubsub/bus'
import getErrorsList from '@frontend/helpers/getErrorsList'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiDemoStudentRegistration from 'Api/rest/Api/Auth/DemoStudentRegistration'
import ApiOptionsDemoEducationTypes from 'Api/rest/Api/Auth/Options/DemoEducationTypes'
import ApiOptionsDemoGrades from 'Api/rest/Api/Auth/Options/DemoGrades'
import { showToast, showToastPersistent } from 'Helpers/toast'

const globalMessageOnRegister = function () {
  eventBus.$emit('frontend.auth.leadCreated')
}

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async register({ state, commit }) {
    const payload = {
      firstName: state.firstName,
      email: state.email,
      grade: state.grade,
      educationType: state.educationType,
      parentPhone: state.parentPhone,
      parentName: state.parentName,
    }

    try {
      const { status } = await ApiDemoStudentRegistration.post(payload)

      if (status === HttpStatusCodes.Created) {
        showToast(
          i18n.t(
            'store.auth.toasted_message.success.registration_completed_successfully',
          ),
          'success',
        )

        commit('setInputValue', {
          name: 'isRegistrationSucceed',
          value: true,
        })

        globalMessageOnRegister()
      }
    } catch (error) {
      // TODO: -> component
      if (error?.response?.data?.message) {
        showToast(error.response.data.message, 'error')
      } else {
        showToastPersistent(getErrorsList(error), 'error')
      }
    }
  },

  async fetchGrades({ commit }, payload) {
    const { data } = await ApiOptionsDemoGrades.get({
      educationType: {
        id: payload.id,
      },
    })

    commit('setInputValue', {
      name: 'grades',
      value: data,
    })
  },

  async fetchFormEducation({ commit }) {
    const { data } = await ApiOptionsDemoEducationTypes.get()

    commit('setInputValue', {
      name: 'educationTypes',
      value: data,
    })
  },
}
