export const scrollTo = (coordinate = 0) => {
  // TODO: Если в будущем кол-во не поддерживаемого апи (для старых браузеров) будет увеличиваться, то подумать над добавлением Modernizr
  const supportsNativeSmoothScroll =
    'scrollBehavior' in document.documentElement.style

  supportsNativeSmoothScroll
    ? window.scrollTo({
        top: coordinate,
        behavior: 'smooth',
      })
    : window.scrollTo(0, coordinate)
}

/**
 * Плавный скролл наверх
 */
export const scrollToTop = () => {
  scrollTo(0)
}
