import actions from '@frontend/store/courses/common/purchase/actions'
import getters from '@frontend/store/courses/common/purchase/getters'
import mutations from '@frontend/store/courses/common/purchase/mutations'
import state from '@frontend/store/courses/common/purchase/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
