import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiDemoFeSimpleRequestCall = new (class extends ApiBase {
  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  put(params = {}) {
    const urlParams = UrlHelper.param(params)
    const url = `/auth/demo-fe-student-request-call?${urlParams}`

    return this._PUT(url)
  }
})()
