<template>
  <div>
    <BannersBlock
      v-if="isRendered.bannersBlock"
      class="mb-2 d-block d-lg-none"
      :banners="banners"
    />

    <PromoBlock
      v-if="isRendered.promoBlock"
      class="mb-2 d-block d-lg-none"
      :promo-products="promoProducts"
    />

    <section :class="tabClass">
      <div v-if="hasSubjects">
        <div
          v-if="educationType.fullTime"
          class="home__subjects-block home__title-full-time"
        >
          <div class="form-row">
            <div class="col-8">
              <span class="t-tag">{{ t('subject') }}</span>
            </div>

            <div class="col-4 text-right">
              <span class="t-tag">{{ t('point') }}</span>
            </div>
          </div>
        </div>

        <div v-if="subjectsRequired.length" class="home__subjects-block">
          <SubjectItemPreview
            v-for="subject in subjectsRequired"
            :key="subject.group.id"
            :subject="subject"
          />

          <template v-if="isRendered.promoCtps">
            <PromoSubjectItemPreview
              v-for="promoCtp in promoCtps"
              :key="uniqueId(promoCtp.id)"
              :promo-ctp="promoCtp"
            />
          </template>
        </div>

        <div v-if="subjectsOptional.length" class="home__subjects-block">
          <SubjectItemPreview
            v-for="subject in subjectsOptional"
            :key="subject.group.id"
            :subject="subject"
          />
        </div>

        <div v-if="subjectsAdditional.length" class="home__subjects-block">
          <SubjectItemPreview
            v-for="subject in subjectsAdditional"
            :key="subject.group.id"
            :subject="subject"
          />
        </div>

        <div v-if="subjectsExtracurricular.length" class="home__subjects-block">
          <!-- TODO: i18n -->
          <div class="t-tag mb-2">Мои курсы</div>

          <SubjectItemPreview
            v-for="subject in subjectsExtracurricular"
            :key="subject.group.id"
            :subject="subject"
          />
        </div>
      </div>

      <div v-else class="b-white__side">
        <div class="text-placeholder text-gray">
          {{ t('no_subjects') }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BannersBlock from '@frontend/components/common/BannersBlock.vue'
import PromoBlock from '@frontend/components/common/promo/PromoBlock.vue'
import PromoSubjectItemPreview from '@frontend/components/common/promo/PromoSubjectItemPreview.vue'
import SubjectItemPreview from '@frontend/components/student/subjects/SubjectItemPreview.vue'
import { mainTabsStudent } from '@frontend/helpers/mainTabs'
import HelpersMixin from '@frontend/mixins/HelpersMixin'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Subjects',

  components: {
    PromoBlock,
    BannersBlock,
    PromoSubjectItemPreview,
    SubjectItemPreview,
  },

  mixins: [
    HelpersMixin,
  ],

  props: {
    currentTab: {
      type: String,
      default: '',
    },

    subjectsRequired: {
      type: Array,
      default: () => [],
    },

    subjectsOptional: {
      type: Array,
      default: () => [],
    },

    subjectsAdditional: {
      type: Array,
      default: () => [],
    },

    subjectsExtracurricular: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState('common/promoCampaign', [
      'promoCtps',
      'promoProducts',
    ]),

    ...mapState('common/productAd', [
      'banners',
    ]),

    ...mapGetters('student/profile', [
      'educationType',
      'isSchoolEducont',
      'isAvailableAnyCommercialOffer',
    ]),

    tabClass() {
      return [
        'home__tab-pane',
        'home__subjects',
        {
          active: this.currentTab === mainTabsStudent.subjects.id,
          'home__subjects--wide':
            this.educationType.fullTime || this.isSchoolEducont,
        },
      ]
    },

    hasSubjects() {
      return (
        this.subjectsRequired.length ||
        this.subjectsOptional.length ||
        this.subjectsAdditional.length ||
        this.subjectsExtracurricular.length
      )
    },

    isRendered() {
      return {
        promoBlock:
          this.isAvailableAnyCommercialOffer &&
          this.promoProducts.length > 0 &&
          this.currentTab === 'subjects',

        bannersBlock:
          this.isAvailableAnyCommercialOffer &&
          this.banners.length > 0 &&
          this.currentTab === 'subjects',

        promoCtps: this.isAvailableAnyCommercialOffer,
      }
    },
  },

  methods: {
    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`components.main.subjects.${name}`)
    },
  },
}
</script>

<style lang="scss">
@import '~@frontend/scss/variables';

.home {
  &__subjects {
    height: 100%;
    background-color: $white;
    box-shadow: 0 2px 24px rgb(51 47 62 / 8%);

    @media (min-width: $lg) {
      width: 200px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    &--wide {
      @media (min-width: $lg) {
        width: 280px;
      }
    }
  }

  &__subjects-block {
    padding: 16px;
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: 0;
    }

    @media (min-width: $lg) {
      padding: 32px;
    }
  }

  &__title-full-time {
    padding-bottom: 0;
    margin-bottom: -16px;
    border: 0;
  }
}
</style>
