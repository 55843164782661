<template>
  <ElModal
    :name="name"
    :width="700"
    :adaptive="true"
    :click-to-close="false"
    transition="none"
    class="modal-content"
  >
    <div class="vm--modal-content">
      <h3 class="h3">{{ title }}</h3>

      <p v-html="description"></p>

      <div :class="$style.cards" class="mb-3">
        <ProductCard
          v-for="product in products"
          :key="`${product.id}-${product.offer.id}`"
          :product="product"
          :is-selected="isSelected.product(product)"
          @click="onClickProductCard(product)"
        />
      </div>

      <div class="d-flex flex-column">
        <ElButton
          class="btn-primary w-100 mb-2"
          :label="labels.btnBuy"
          :disabled="isDisabled.btnBuy"
          @click="onClickBtnBuy"
        />

        <!-- TODO: i18n -->
        <a
          v-if="isRendered.btnShowAllOffers"
          class="btn btn-outline-primary w-100 mb-2"
          :href="linkShowAllOffers"
          @click="onClickBtnShowAllOffers"
          >Показать все предложения</a
        >

        <!-- TODO: i18n -->
        <a
          class="align-self-center d-block text-center text-underline cursor-pointer mt-2 mb-n2"
          @click.prevent="onClickBtnNotInterested"
          >Мне это неинтересно</a
        >
      </div>
    </div>
  </ElModal>
</template>

<script>
import ProductCard from '@frontend/components/client/productAd/ProductCard.vue'
import ElButton from '@frontend/ui/ElButton.vue'
import ElModal from '@frontend/ui/ElModal.vue'
import { omit, values } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalProductAd',

  components: {
    ProductCard,
    ElButton,
    ElModal,
  },

  props: {
    ad: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      name: 'modalProductAd',
      productsSelectedByOfferId: {},
    }
  },

  computed: {
    title() {
      return this.ad?.title ?? ''
    },

    description() {
      return this.ad?.description ?? ''
    },

    products() {
      return this.ad?.products ?? []
    },

    linkShowAllOffers() {
      return this.ad?.link ?? ''
    },

    // TODO: i18n
    labels() {
      return {
        btnBuy: this.ad?.buttonText || 'Купить',
      }
    },

    isSelected() {
      return {
        product: (product) =>
          !!this.productsSelectedByOfferId[product.offer.id.toString()],
      }
    },

    isDisabled() {
      return {
        btnBuy: values(this.productsSelectedByOfferId).length === 0,
      }
    },

    isRendered() {
      return {
        btnShowAllOffers: !!this.linkShowAllOffers,
      }
    },
  },

  methods: {
    onClickProductCard(product) {
      if (this.isSelected.product(product)) {
        this.productsSelectedByOfferId = omit(
          this.productsSelectedByOfferId,
          product.offer.id.toString(),
        )
      } else {
        this.productsSelectedByOfferId = {
          ...this.productsSelectedByOfferId,
          [product.offer.id.toString()]: product,
        }
      }
    },

    onClickBtnBuy() {
      this.$emit('click:btn-buy', {
        ad: this.ad,
        products: values(this.productsSelectedByOfferId),
      })
    },

    onClickBtnShowAllOffers() {
      this.$emit('click:btn-show-all-offers')
    },

    onClickBtnNotInterested() {
      this.$emit('click:btn-not-interested')
    },

    /**
     * @public
     */
    show() {
      this.$modal.show(this.name)
    },

    /**
     * @public
     */
    hide() {
      this.$modal.hide(this.name)
    },
  },
})
</script>

<style lang="scss" module>
.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
</style>
