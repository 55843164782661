import { StoreUserShowcase } from '@frontend/StoreUserShowcase'
import { studentEducationRoutes } from '@frontend/routes/showcase/student/studentEducationRoutes'
import { studentMainRoutes } from '@frontend/routes/showcase/student/studentMainRoutes'
import { studentShowcaseRoutes } from '@frontend/routes/showcase/student/studentShowcaseRoutes'
import { studentSupportRoutes } from '@frontend/routes/showcase/student/studentSupportRoutes'
import { studentTestRoutes } from '@frontend/routes/showcase/student/studentTestRoutes'

const checkRole = (to, from, next) => {
  if (StoreUserShowcase.store.getters['common/user/isStudent']) {
    next()
  } else {
    next({
      name: 'guest-showcase',
    })
  }
}

export const studentRoutes = [
  {
    path: '/student',
    component: () =>
      import(
        /* webpackChunkName: "showcase-student" */
        '@frontend/layouts/showcase/StudentMain.vue'
      ),
    meta: {
      isDisabledScroll: true,
    },

    children: [
      ...studentMainRoutes,
      ...studentShowcaseRoutes,
      ...studentSupportRoutes,
      ...studentEducationRoutes,
    ],
  },

  ...studentTestRoutes,

  {
    path: '/student/online-lessons/:onlineLessonId(\\d+)/video/:videoId(\\d+)',
    name: 'student-showcase-the-online-lesson-preview',
    component: () =>
      import(
        /* webpackChunkName: "showcase-the-online-lesson-preview" */ '@frontend/pages/showcase/common/TheLessonOnlinePreview.vue'
      ),
  },
]

studentRoutes.forEach((route) => {
  route.beforeEnter = checkRole
})
