import { decode } from '@admin/helpers/admin/userSettings/coding'
import { ApiCoursesProducts } from 'ApiRest/Api/Courses/Products'
import { ApiCoursesProductsFilters } from 'ApiRest/Api/Courses/Products/Filters'
import { forIn } from 'lodash'

export default {
  applyQuery({ state, getters, commit }, payload) {
    if (payload.settings) {
      let settings

      try {
        settings = JSON.parse(decode(payload.settings))
      } catch {
        settings = {}
      }

      const sort =
        state.sort.options.find((option) => option.value === settings.sort) ??
        state.sort.options[0]

      commit('setSort', sort)

      const { page, rowsPerPage } = settings

      commit('setPage', page)

      if (state.pagination.rowsPerPageOptions.includes(rowsPerPage)) {
        commit('setRowsPerPage', rowsPerPage)
      }

      const categoryId = settings.category?.id

      if (categoryId && getters.categoriesById[categoryId]) {
        commit('setCategoryId', categoryId)
      } else {
        commit('unsetCategoryId')
      }

      commit('setSearch', settings.search ?? '')
      commit('setTag', settings.tag ?? '')

      forIn(settings.filters, (value, code) => {
        commit('setFilterValue', {
          code,
          value,
        })
      })
    }
  },

  async fetchFilters({ commit }) {
    const { data } = await ApiCoursesProductsFilters.get()

    commit('setFilters', data)
  },

  async fetchProducts({ state, getters, commit, dispatch }) {
    const { data, headers } = await ApiCoursesProducts.get(getters.settings)

    commit('setProducts', data)
    commit('updatePagination', headers)

    if (state.products.length === 0) {
      await dispatch('fetchProductsPopular')
    }
  },

  async fetchProductsPopular({ commit }) {
    const { data } = await ApiCoursesProducts.get({
      sort: '-popularity',
      page: 1,
      rowsPerPage: 3,
    })

    commit('setProductsPopular', data)
  },
}
