import { RESET_STATE } from 'Helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {string} payload.target
   * @param {number} payload.count
   */
  setNotificationsCount(state, { target, count = 0 }) {
    const targetLink = state.links.find((link) => link.link.name === target)

    if (targetLink) {
      targetLink.notifications = count
    }
  },

  setMenuLinkValue(state, { linkName, params, query }) {
    const index = state.links.findIndex((link) => link.link.name === linkName)

    if (index === -1) {
      throw new Error("Link names don't match")
    }

    if (params) {
      state.links[index].link.params = params
    }

    if (query) {
      state.links[index].link.query = query
    }
  },
}
