import { i18n } from '@/i18n/setup'
import { eventBus } from '@/pubsub/bus'
import { RouterUser } from '@frontend/RouterUser'
import getErrorsList from '@frontend/helpers/getErrorsList'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiDemoStudentRegistration from 'Api/rest/Api/Account/Client/Students/DemoRegistration'
import ApiOptionsDemoEducationTypes from 'Api/rest/Api/Auth/Options/DemoEducationTypes'
import ApiOptionsDemoGrades from 'Api/rest/Api/Auth/Options/DemoGrades'
import { showToast, showToastPersistent } from 'Helpers/toast'

const globalMessageOnSendForm = function () {
  eventBus.$emit('frontend.client.demoAccess.studentCreated')
}

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async sendForm({ state, dispatch, rootState, rootGetters }) {
    const body = {
      email: state.email,
      firstName: state.firstName,
      lastName: state.lastName,
      middleName: state.middleName,
      grade: state.grade,
      educationType: state.educationType,
      parentName: rootState.client.profile.profile.firstName,
      parentPhone: rootState.client.profile.profile.phone,
    }

    // TODO: -> component
    try {
      const { status } = await ApiDemoStudentRegistration.post(body)

      if (status === HttpStatusCodes.Created) {
        showToast(
          i18n.t('store.client.toasted_message.student_successfully_created'),
          'success',
        )

        showToast(
          `${i18n.t('store.client.to_mail')} ${state.email} ${i18n.t(
            'store.client.was_sent',
          )}`,
          'success',
        )

        if (rootGetters['client/students/hasEnrolledStudents']) {
          await RouterUser.router.push({
            name: 'client-main',
          })
        } else {
          if (rootGetters['client/contracts/contractsList']?.length) {
            await RouterUser.router.push({
              name: 'client-contracts',
            })
          } else {
            await RouterUser.router.push({
              name: 'client-request-new',
            })
          }
        }

        await dispatch('client/students/fetchStudents', null, {
          root: true,
        })

        globalMessageOnSendForm()
      }
    } catch (error) {
      if (error.response?.data) {
        const message = getErrorsList(error)

        showToastPersistent(message, 'error')
      }
    }
  },

  async fetchDemoGrades({ commit }, payload) {
    const { data } = await ApiOptionsDemoGrades.get({
      educationType: {
        id: payload.id,
      },
    })

    commit('setDemoGrades', data)
  },

  async fetchDemoFormEducation({ commit }) {
    const { data } = await ApiOptionsDemoEducationTypes.get()

    commit('setDemoFormEducation', data)
  },
}
