import client from '@frontend/store/courses/client/store'
import common from '@frontend/store/courses/common/store'
import student from '@frontend/store/courses/student/store'

export default {
  namespaced: true,

  modules: {
    client,
    common,
    student,
  },
}
