import Constants from 'Constants'
import dateFormatHelper from 'Helpers/dateFormatHelper'

/**
 * @param {Object} period
 * @returns {boolean}
 */
export const isCertificationAvailableByDate = (period) => {
  const { priorityDateEnd, scheduleDateBegin, scheduleDateEnd } = period

  if (priorityDateEnd) {
    return new Date(dateFormatHelper.toIso(priorityDateEnd)) > new Date()
  }

  return (
    new Date(dateFormatHelper.toIso(scheduleDateBegin)) >
    new Date() <
    new Date(dateFormatHelper.toIso(scheduleDateEnd))
  )
}

/**
 * Получаем актуальную аттестацию на странице (ЛК Клиента и ЛК Ученика )
 * для дефолтного отображения в табах.
 *
 * Два условия актуальной аттестации:
 * 1. Статус «Готова к аттестации» + Индивидуальная дата завершения аттестации еще не прошла
 * 2. Статус «Начата» + Индивидуальная дата завершения аттестации еще не прошла
 * @param {Array} certificationTabs
 * @returns {Object|null}
 */
export const getActualCertification = (certificationTabs) => {
  const getCertificationByStatus = (status) =>
    certificationTabs.find(
      (certification) =>
        certification.requestStatus?.id === status &&
        isCertificationAvailableByDate(certification.requestPeriod) === true,
    )

  const { READY_TO_GO, STARTED } = Constants.familyEducationCertRequestStatus

  const readyToGoCertification = getCertificationByStatus(READY_TO_GO)
  const startedCertification = getCertificationByStatus(STARTED)

  if (readyToGoCertification) {
    return readyToGoCertification
  }

  if (startedCertification) {
    return startedCertification
  }

  return null
}
