import createState from '@frontend/store/showcase/common/cart/state'
import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

const createOfferDoc = (offerDoc) => ({
  ...offerDoc,
  blob: '',
  isChecked: false,
})

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setInvoices(state, invoices) {
    state.invoices = invoices
  },

  setTheInvoice(state, payload) {
    state.invoice = payload
  },

  setTheInvoicePaymentUrl(state, payload) {
    state.paymentUrl = payload
  },

  setPurchasedProduct(state, payload) {
    state.purchasedProduct = payload
  },

  setOfferDocs(state, payload = []) {
    state.offerDocs = payload.map((offerDoc) => createOfferDoc(offerDoc))
  },

  setOfferDocChecked(state, { offerDoc, isChecked }) {
    const offerDocFound = state.offerDocs.find((doc) => doc.id === offerDoc.id)

    if (offerDocFound) {
      offerDocFound.isChecked = isChecked
    }
  },

  setOfferDocBlob(state, { id, payload }) {
    const doc = state.offerDocs.find((offerDoc) => offerDoc.id === id)

    Vue.set(doc, 'blob', payload)
  },

  setRecurrentPaymentsDocBlob(state, { id, payload }) {
    const doc = state.invoice.recurrentPaymentsDocuments.find(
      (document) => document.id === id,
    )

    Vue.set(doc, 'blob', payload)
  },

  setServerTimestamp(state, payload) {
    state.serverTimestamp = payload
  },
}
