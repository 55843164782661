import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiCoursesProducts = new (class extends ApiBase {
  get(params = {}) {
    const urlParams = UrlHelper.param(params)
    const url = `/courses/products?${urlParams}`

    return this._GET(url)
  }
})()
