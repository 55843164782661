<template>
  <ElModal
    :name="name"
    :width="500"
    :adaptive="true"
    transition="none"
    class="modal-content"
  >
    <div class="vm--modal-content">
      <ElButton class="btn-img vm--modal-close" @click="onClickBtnClose">
        <img :src="icons.iconClose" alt="icon close" />
      </ElButton>

      <!-- TODO: i18n -->
      <h3 class="h3">Приветствуем вас на платформе IBLS!</h3>

      <!-- TODO: i18n -->
      <div class="mb-3 text-gray">
        Сейчас вы находитесь в личном кабинете клиента. Для начала обучения
        перейдите в личный кабинет ученика – это можно сделать, нажав на кнопку,
        расположенную на верхней синей панели. В кабинете ученика вы найдете
        видеоуроки, тесты и демо-версии контрольных работ.
      </div>

      <!-- TODO: i18n -->
      <div class="mb-5 text-gray">
        Из личного кабинета клиента можно отслеживать успеваемость обучающегося,
        а также подать заявление на промежуточную аттестацию. Данные для входа в
        личный кабинет ученика были отправлены на его электронную почту,
        указанную вами при регистрации.
      </div>

      <div>
        <!-- TODO: i18n -->
        <ElButton
          class="btn-primary"
          label="Приступить к обучению"
          @click="onClickBtnStartSchool"
        />
      </div>
    </div>
  </ElModal>
</template>

<script>
import ElButton from '@frontend/ui/ElButton.vue'
import ElModal from '@frontend/ui/ElModal.vue'
import iconClose from 'Static/images/@frontend/common-lk/icons/close.svg'

export default {
  name: 'ModalWelcome',

  components: {
    ElModal,
    ElButton,
  },

  data() {
    return {
      name: 'modalContractPaymentSuccessWelcome',

      icons: {
        iconClose,
      },
    }
  },

  methods: {
    onClickBtnClose() {
      this.hide()
    },

    onClickBtnStartSchool() {
      this.hide()
    },

    /**
     * @public
     */
    show() {
      this.$modal.show(this.name)
    },

    /**
     * @public
     */
    hide() {
      this.$modal.hide(this.name)
    },
  },
}
</script>
