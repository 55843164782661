import { studentSubjectRoutes } from '@frontend/routes/showcase/student/studentSubjectRoutes'

export const studentEducationRoutes = [
  {
    path: 'education',
    name: 'student-showcase-education',

    component: () =>
      import(
        /* webpackChunkName: "StudentEducationLayout" */
        '@frontend/layouts/showcase/StudentEducationLayout.vue'
      ),

    children: [
      ...studentSubjectRoutes,

      {
        path: 'purchases',
        name: 'student-showcase-purchases',
        component: () =>
          import(
            /* webpackChunkName: "showcase-purchases" */
            '@frontend/pages/showcase/common/Purchases.vue'
          ),
      },
    ],
  },
]
