<template>
  <ElModal
    :max-width="600"
    :name="name"
    :class="$style.modal"
    @opened="onOpened"
    @closed="onClosed"
  >
    <LoaderCircle v-if="isLoading" />

    <div v-else>
      <ElButton class="btn-img vm--modal-close" @click="onClickBtnHide">
        <img :src="icons.close" alt="close" />
      </ElButton>

      <div class="vm--modal-content">
        <div>
          <div class="h3-modal">
            {{ promoCampaign.name }}
          </div>

          <div class="mb-3" v-html="promoCampaign.description"></div>

          <div class="row mb-3" :class="$style.product">
            <div class="col-12 col-sm-5">
              <div :class="$style.image">
                <figure class="figure">
                  <img :src="imageSrc" alt="cover" class="figure-img" />
                </figure>
              </div>
            </div>

            <div class="col-12 col-sm-7">
              <h3 class="pt-2">{{ productName }}</h3>

              <div v-html="productDescription"></div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <!-- TODO: i18n -->

          <ElButton
            class="btn-primary"
            :label="labelBtnGo"
            @click="onClickBtnGo"
          />

          <!-- TODO: i18n -->
          <ElButton
            label="Отмена"
            class="btn-outline-primary"
            @click="onClickBtnHide"
          />
        </div>
      </div>
    </div>
  </ElModal>
</template>

<script>
import ElButton from '@frontend/ui/ElButton.vue'
import ElModal from '@frontend/ui/ElModal.vue'
import LoaderCircle from '@frontend/ui/LoaderCircle.vue'
import { ErrorHelper } from 'Helpers/ErrorHelper'
import { isNil, truncate, uniqueId } from 'lodash'
import iconClose from 'Static/images/@frontend/common-lk/icons/close.svg'
import imageLogo from 'Static/images/productCover.jpg'
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default defineComponent({
  name: 'ModalPromoMaterials',

  components: {
    ElButton,
    ElModal,
    LoaderCircle,
  },

  props: {
    promoCampaignId: {
      type: Number,
      default: null,
    },

    promoActionLogTypeId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      icons: {
        close: iconClose,
      },

      isLoading: true,
    }
  },

  computed: {
    ...mapState('common/promoCampaign', [
      'promoCampaign',
    ]),

    ...mapState('common/user', [
      'role',
    ]),

    ...mapGetters('common/user', [
      'getRouteNameOnUser',
    ]),

    imageSrc() {
      return this.promoCampaign.product.image?.url ?? imageLogo
    },

    productDescription() {
      const { description = '' } = this.promoCampaign.product

      return truncate(description, {
        length: 203,
      })
    },

    productName() {
      return this.promoCampaign.product.name ?? ''
    },

    isShowcaseProduct() {
      return this.promoCampaign.product.isShowcase
    },

    // TODO: i18n
    labelBtnGo() {
      return this.promoCampaign.buttonText || 'Перейти'
    },

    name() {
      return uniqueId('modalName-')
    },
  },

  methods: {
    ...mapActions('common/promoCampaign', [
      'fetchPromoCampaign',
      'logClicksPromoMaterials',
    ]),

    async onClickBtnGo() {
      await this.logClicksPromoMaterials({
        promoActionLogTypeId: this.promoActionLogTypeId,
        product: {
          id: this.promoCampaign.product.id,
        },
      })

      if (this.isShowcaseProduct) {
        const { protocol } = window.location
        const { showcase } = window.domains
        const { mainCategory, seo } = this.promoCampaign.product

        const urlProductShowcase = `${protocol}//${showcase}/${this.role}/category/${mainCategory.seo.prettyLink}/${seo.prettyLink}`

        window.location.href = urlProductShowcase
      } else {
        await this.$router.push({
          name: this.getRouteNameOnUser('courses-product'),
          params: {
            productId: this.promoCampaign.product?.id,
          },
        })
      }
    },

    /** @public */
    show() {
      this.$modal.show(this.name)
    },

    hide() {
      this.$modal.hide(this.name)
    },

    onClickBtnHide() {
      this.hide()
    },

    async onOpened() {
      if (!isNil(this.promoCampaignId)) {
        this.isLoading = true

        try {
          await this.fetchPromoCampaign(this.promoCampaignId)
        } catch (error) {
          new ErrorHelper(error).processError()
          this.hide()
        } finally {
          this.isLoading = false
        }
      }
    },

    onClosed() {
      this.isLoading = true
    },
  },
})
</script>

<style lang="scss" module>
@import '~@frontend/scss/variables';

.modal {
  [class^='vm--modal'] {
    max-height: 90%;
  }

  [class~='figure'] {
    $padding: 16px;

    display: flex;
    width: calc(100% + #{$padding * 2});
    margin: 0 -#{$padding};
    overflow: hidden;
    aspect-ratio: 3 / 2;
  }

  [class~='figure-img'] {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-bottom: 0;
  }

  iframe,
  img {
    max-width: 100%;
    height: auto;
  }

  p {
    font-weight: 400;
  }
}

.image {
  @media (max-width: $sm) {
    flex: 0 0 30%;

    [class~='figure'] {
      width: 128px;
      height: 128px;
      max-height: unset;
      margin: 0;
      overflow: hidden;
      border-radius: 8px;
    }

    [class~='figure-img'] {
      object-fit: cover;
    }
  }
}

.product {
  border: 1px solid rgba(51, 47, 62, 0.125);
  border-radius: 8px;
  margin: 0;
}
</style>
