import { ApiStudentShowcaseCtpStatementAcademicYears } from 'ApiRest/Api/Account/Student/ShowcaseCtpStatement/AcademicYears'
import { ApiStudentShowcaseCtpStatement } from 'ApiRest/Api/Account/Student/ShowcaseCtpStatement/Statement'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchStatements({ commit, state }) {
    const { data } = await ApiStudentShowcaseCtpStatement.get({
      academicYearPreset: {
        id: state.academicYear.id,
      },
    })

    commit('setStatements', data)
  },

  async fetchAcademicYears({ commit }) {
    const { data } = await ApiStudentShowcaseCtpStatementAcademicYears.get()

    commit('setAcademicYears', data)
  },
}
