import { mainTabsStudent } from '@frontend/helpers/mainTabs'
import { getShowcaseDomain } from 'Helpers/getShowcaseDomain'

export default () => ({
  currentTab: mainTabsStudent[Object.keys(mainTabsStudent)[0]].id,

  links: [
    {
      link: {
        name: 'subjects',
      },
      labelPath: 'nav.subjects',
      class: 'm-subjects',
      notifications: 0,
    },
    {
      link: {
        name: 'lessons',
      },
      labelPath: 'nav.lessons',
      class: 'm-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'online-lessons',
      },
      labelPath: 'nav.online_lessons',
      class: 'm-online',
      notifications: 0,
    },
    {
      link: {
        name: 'student-statements',
      },
      labelPath: 'nav.statement',
      class: 'm-transcript',
      notifications: 0,
    },
    {
      link: {
        name: 'student-income-diagnostics',
      },
      labelPath: 'nav.income_diagnostics',
      class: 'm-assessment',
      notifications: 0,
    },
    // TODO: temporary hide link. https://redmine.ibls.tech/issues/4139. Согласовано с Ушаковым
    // {
    //  link: {
    //    name: 'student-events',
    //  },
    //  label: 'События',
    //  labelPath: 'nav.events',
    //  class: 'm-events',
    //  notifications: 0,
    //  isExternal: true,
    //  externalUrl: 'https://events.iblschool.ru/',
    // },
    {
      link: {
        name: 'student-showcase',
      },
      labelPath: 'nav.marketplace',
      class: 'm-showcase',
      notifications: 0,
      isExternal: true,
      externalUrl: `${getShowcaseDomain()}/student`,
    },
    {
      link: {
        name: 'student-courses',
      },
      labelPath: 'nav.courses',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'certification',
      },
      labelPath: 'nav.attestation',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'calendar',
      },
      labelPath: 'nav.calendar',
      class: 'm-calendar',
      notifications: 0,
    },
    {
      link: {
        name: 'goals',
      },
      labelPath: 'nav.goals',
      class: 'm-goals',
      notifications: 0,
    },
    {
      link: {
        name: 'student-messages',
      },
      labelPath: 'nav.messages',
      class: 'm-messages',
      notifications: 0,
    },
    {
      link: {
        name: 'notifications',
      },
      labelPath: 'nav.notifications',
      class: 'm-notifications',
      notifications: 0,
    },
    {
      link: {
        name: 'student-news',
      },
      labelPath: 'nav.news',
      class: 'm-news',
      notifications: 0,
    },
    {
      link: {
        name: 'student-library',
      },
      labelPath: 'nav.library',
      class: 'm-library',
      notifications: 0,
    },

    {
      link: {
        name: 'schedule-tests',
      },
      labelPath: 'nav.tests',
      class: 'm-schedule-tests',
      notifications: 0,
    },
    {
      link: {
        name: 'schedule-lessons',
      },
      labelPath: 'nav.schedule',
      class: 'm-schedule-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'student-support',
      },
      labelPath: 'nav.support',
      class: 'm-help',
      notifications: 0,
    },
  ],

  linksExtramural: [
    {
      link: {
        name: 'student-lessons-group',
      },
      labelPath: 'nav.subjects',
      class: 'm-subjects',
      notifications: 0,
      children: [
        {
          link: {
            name: 'subjects',
          },
          labelPath: 'nav.subjects',
          class: 'm-subjects',
          notifications: 0,
        },
        {
          link: {
            name: 'student-statements',
          },
          labelPath: 'nav.statement',
          class: 'm-transcript',
          notifications: 0,
        },
        {
          link: {
            name: 'lessons',
          },
          labelPath: 'nav.lessons',
          class: 'm-lessons',
          notifications: 0,
        },
      ],
    },
    {
      link: {
        name: 'student-calendar-group',
      },
      labelPath: 'nav.schedule',
      class: 'm-calendar',
      notifications: 0,
      children: [
        {
          link: {
            name: 'online-lessons',
          },
          labelPath: 'nav.online_lessons',
          class: 'm-online',
          notifications: 0,
        },
        {
          link: {
            name: 'calendar',
          },
          labelPath: 'nav.calendar',
          class: 'm-calendar',
          notifications: 0,
        },
        {
          link: {
            name: 'goals',
          },
          labelPath: 'nav.goals',
          class: 'm-goals',
          notifications: 0,
        },
      ],
    },
    {
      link: {
        name: 'student-showcase',
      },
      labelPath: 'nav.marketplace',
      class: 'm-showcase',
      notifications: 0,
      isExternal: true,
      externalUrl: `${getShowcaseDomain()}/student`,
    },
    {
      link: {
        name: 'student-courses',
      },
      labelPath: 'nav.courses',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'student-library',
      },
      labelPath: 'nav.library',
      class: 'm-library',
      notifications: 0,
    },
    {
      link: {
        name: 'student-income-diagnostics',
      },
      labelPath: 'nav.income_diagnostics',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'student-information',
      },
      labelPath: 'nav.information',
      class: 'm-help',
      notifications: 0,
      children: [
        {
          link: {
            name: 'student-news',
          },
          labelPath: 'nav.news',
          class: 'm-news',
          notifications: 0,
        },
        {
          link: {
            name: 'student-support',
          },
          labelPath: 'nav.support',
          class: 'm-help',
          notifications: 0,
        },
      ],
    },
  ],

  linksFamily: [
    {
      link: {
        name: 'student-main',
      },
      labelPath: 'nav.home',
      class: 'm-home',
      notifications: 0,
    },
    {
      link: {
        name: 'student-learn-group',
      },
      labelPath: 'nav.subjects',
      class: 'm-subjects',
      notifications: 0,
      children: [
        {
          link: {
            name: 'subjects',
          },
          labelPath: 'nav.subjects',
          class: 'm-subjects',
          notifications: 0,
        },
        {
          link: {
            name: 'student-statements',
          },
          // TODO: i18n
          labelPath: 'Ведомость курсов',
          class: 'm-transcript',
          notifications: 0,
        },
      ],
    },
    {
      link: {
        name: 'calendar',
      },
      labelPath: 'nav.calendar',
      class: 'm-calendar',
      notifications: 0,
    },
    {
      link: {
        name: 'lessons',
      },
      labelPath: 'nav.lessons',
      class: 'm-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'online-lessons',
      },
      labelPath: 'nav.online_lessons',
      class: 'm-online',
      notifications: 0,
    },
    {
      link: {
        name: 'student-income-diagnostics',
      },
      labelPath: 'nav.income_diagnostics',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'student-showcase',
      },
      labelPath: 'nav.marketplace',
      class: 'm-showcase',
      notifications: 0,
      isExternal: true,
      externalUrl: `${getShowcaseDomain()}/student`,
    },
    {
      link: {
        name: 'student-courses',
      },
      labelPath: 'nav.courses',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'certification',
      },
      labelPath: 'nav.attestation',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'goals',
      },
      labelPath: 'nav.goals',
      class: 'm-goals',
      notifications: 0,
    },
    {
      link: {
        name: 'student-messages',
      },
      labelPath: 'nav.messages',
      class: 'm-messages',
      notifications: 0,
    },
    {
      link: {
        name: 'notifications',
      },
      labelPath: 'nav.notifications',
      class: 'm-notifications',
      notifications: 0,
    },
    {
      link: {
        name: 'student-news',
      },
      labelPath: 'nav.news',
      class: 'm-news',
      notifications: 0,
    },
  ],
})
