export const clientPaymentRoutes = [
  {
    path: 'payment',
    name: 'client-payment',
    component: () =>
      import(
        /* webpackChunkName: "client-payment" */
        '@frontend/pages/client/payments/Payments.vue'
      ),
    children: [
      {
        path: 'invoices',
        name: 'client-payment-invoices',
        component: () =>
          import(
            /* webpackChunkName: "client-payment-invoices" */
            '@frontend/pages/client/Invoices.vue'
          ),
      },
      {
        path: 'subscriptions',
        name: 'client-payment-subscriptions',
        component: () =>
          import(
            /* webpackChunkName: "client-payment-subscriptions" */
            '@frontend/pages/client/payments/Subscriptions.vue'
          ),
      },
    ],
  },
  {
    path: 'payment/invoices/:invoiceId(\\d+)',
    name: 'client-payment-the-invoice',
    component: () =>
      import(
        /* webpackChunkName: "client-payment-the-invoice" */
        '@frontend/pages/client/TheInvoice.vue'
      ),
  },
]
