import { RESET_STATE } from 'Helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setBanners(state, value) {
    state.banners = value
  },

  setModalData(state, value) {
    state.modalData = value
  },

  setPurchase(state, value) {
    state.purchase = value
  },
}
