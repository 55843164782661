import { i18n } from '@/i18n/setup'
import { RouterUser } from '@frontend/RouterUser'
import HttpHeaders from 'Api/const/HttpHeaders'
import ApiAccountClientNews from 'ApiRest/Api/Account/Client/Students/News'
import ApiAccountClientNewsList from 'ApiRest/Api/Account/Client/Students/News/List'
import ApiAccountClientNewsTags from 'ApiRest/Api/Account/Client/Students/News/Tags'
import { showToast } from 'Helpers/toast'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  /**
   * Список новостей
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} context.rootState
   * @param {Object} params
   * @returns {Promise<void>}
   */
  async fetchNews({ commit, rootState }, params) {
    const studentId = rootState.client.students.currentStudent.id
    const { data, headers } = await ApiAccountClientNewsList.get(
      studentId,
      params,
    )
    const { PAGINATION_ROWS_TOTAL, PAGINATION_PAGE } = HttpHeaders

    if (params?.page === 1) {
      commit('setNews', data)
    } else {
      commit('pushNews', data)
    }

    commit('setNewsPaginationPageCount', headers[PAGINATION_ROWS_TOTAL])
    commit('setNewsPaginationCurrentPage', headers[PAGINATION_PAGE])
  },

  /**
   * Список тегов
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} context.rootState
   * @returns {Promise<void>}
   */
  async fetchTags({ commit, rootState }) {
    const studentId = rootState.client.students.currentStudent.id

    const { data } = await ApiAccountClientNewsTags.get(studentId)

    commit('setTags', data)
  },

  /**
   * Отдельная новость
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} context.rootState
   * @param {number} newsId - id новости
   * @returns {Promise<void>}
   */
  async fetchNewsArticle({ commit, rootState }, newsId) {
    try {
      const studentId = rootState.client.students.currentStudent.id

      const { data } = await ApiAccountClientNews.get(studentId, newsId)

      commit('setNewsArticle', data)
    } catch (error) {
      // TODO: -> component
      showToast(
        i18n.t('store.client.toasted_message.news_was_not_found'),
        'error',
      )

      await RouterUser.router.push({
        name: 'client-news',
      })
    }
  },
}
