import { hasJustPaidContractDemoFeSimple } from '@frontend/helpers/demoFeSimple/student'
import { hasJustPaidContractFe } from '@frontend/helpers/fe/student'
import Constants from 'Constants'
import { feature } from 'Helpers/feature'
import {
  getFullName,
  getNameAbbreviation,
  getNameWithShortLastName,
} from 'Helpers/fullName'

export default {
  fullName(state) {
    return getFullName(state.profile)
  },

  fullNameWithShortLastName(state) {
    return getNameWithShortLastName(state.profile)
  },

  nameAbbreviation(state) {
    return getNameAbbreviation(state.profile)
  },

  streamAcademicYearPreset(state) {
    return state.profile.streamAcademicYearPreset
  },

  isAvailableAnyCommercialOffer(state) {
    return Boolean(!state.profile.hideOffer)
  },

  isDemoProfile(state) {
    return Boolean(state.profile.isDemo)
  },

  demoAccessTime(state) {
    return Number(state.profile.demoAccessTime)
  },

  demoMessage(state) {
    switch (state.profile.educationType?.id) {
      case Constants.educationType.EXTRAMURAL:
        return state.profile.school?.demoMessage ?? ''

      case Constants.educationType.FAMILY:
        return state.profile.school?.demoMessageFe ?? ''

      default:
    }

    return ''
  },

  educationType(state) {
    const id = state.profile.educationType?.id

    return {
      extramural: id === Constants.educationType.EXTRAMURAL,
      family: id === Constants.educationType.FAMILY,
      fullTime: id === Constants.educationType.FULL_TIME,
    }
  },

  isSchoolEducont(state) {
    return Boolean(state.profile.school?.isEducont)
  },

  hasJustPaidContractFe(state) {
    return hasJustPaidContractFe(state.profile)
  },

  hasJustPaidContractDemoFeSimple(state) {
    return hasJustPaidContractDemoFeSimple(state.profile)
  },

  hasAvailablePayment(state, getters, rootState, rootGetters) {
    const isDemoUser = rootGetters['common/user/isDemoUser']
    const { hasAwaitingPayment } = state.profile

    return Boolean(
      (isDemoUser && !getters.hasJustPaidContractDemoFeSimple) ||
        (!isDemoUser && hasAwaitingPayment),
    )
  },

  email(state) {
    return state.profile.email ?? ''
  },

  onlyDiagnostics(state) {
    return state.profile.onlyDiagnostics && feature.isIncomeDiagnostics
  },
}
