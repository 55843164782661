export const studentMainRoutes = [
  {
    path: 'profile',
    name: 'student-profile',
    component: () =>
      import(
        /* webpackChunkName: "student-profile" */
        '@frontend/pages/showcase/student/Profile.vue'
      ),
  },

  {
    path: 'notifications',
    name: 'student-notifications',
    component: () =>
      import(
        /* webpackChunkName: "student-notifications" */
        '@frontend/pages/showcase/common/Notifications.vue'
      ),
  },

  {
    path: 'online-lessons',
    name: 'student-showcase-online-lessons',
    component: () =>
      import(
        /* webpackChunkName: "student-showcase-online-lessons" */
        '@frontend/pages/showcase/common/LessonsOnline.vue'
      ),
  },

  {
    path: 'online-lessons/:lessonId(\\d+)',
    name: 'student-the-online-lesson',
    component: () =>
      import(
        /* webpackChunkName: "the-online-lesson" */
        '@frontend/pages/showcase/common/LessonsOnline.vue'
      ),
  },
]
