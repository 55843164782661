const SECONDS_IN_DAY = 86400

/**
 * @param {number} secondsLeft
 * @returns {string|null}
 */
const getEstimatedTime = (secondsLeft = 0) => {
  if (secondsLeft > 0) {
    const dateNow = Math.round(new Date().getTime() / 1000) // seconds
    const estimatedDate = dateNow + secondsLeft
    const estimatedTime = estimatedDate - dateNow
    const isLessThanDay = estimatedTime < SECONDS_IN_DAY

    let resultText = ''

    if (isLessThanDay) {
      const hours = Math.floor(estimatedTime / 3600)

      if (hours < 1) {
        // TODO: i18n
        resultText = 'менее 1 часа'
      } else {
        const lastDigit = hours % 10
        const isInSecondDecade = hours < 20 && hours > 10
        // TODO: i18n
        let hoursText = 'часов'

        if (lastDigit === 1 && !isInSecondDecade) {
          // TODO: i18n
          hoursText = 'час'
        }

        if (lastDigit > 1 && lastDigit < 5 && !isInSecondDecade) {
          // TODO: i18n
          hoursText = 'часа'
        }

        resultText = `${hours} ${hoursText}`
      }
    } else {
      const days = Math.floor(estimatedTime / SECONDS_IN_DAY)

      const lastDigit = days % 10
      const isInSecondDecade = days < 20 && days > 10
      // TODO: i18n
      let daysText = 'дней'

      if (lastDigit === 1 && !isInSecondDecade) {
        // TODO: i18n
        daysText = 'день'
      }

      if (lastDigit > 1 && lastDigit < 5 && !isInSecondDecade) {
        // TODO: i18n
        daysText = 'дня'
      }

      resultText = `${days} ${daysText}`
    }

    return resultText
  }

  return null
}

export default getEstimatedTime
