import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setCurrentTab(state, tab = '') {
    state.currentTab = tab
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {string} payload.target
   * @param {number} payload.count
   */
  setNotificationsCount(state, { target, count = 0 }) {
    const targetLink = state.links.find((link) => link.link.name === target)

    if (targetLink) {
      targetLink.notifications = count
    }
  },
}
