import ApiOptionsDemoEducationTypes from 'ApiRest/Api/Auth/Options/DemoEducationTypes'
import ApiSchool from 'ApiRest/Api/Auth/Options/School'
import ApiRegistration from 'ApiRest/Api/Auth/Registration'
import ApiSubdomainList from 'ApiRest/Api/School/UnionSubDomain'
import ApiConfirmEmail from 'ApiRest/Api/User/ConfirmEmail'
import { ApiSendConfirmEmail } from 'ApiRest/Api/User/SendConfirmEmail'
import { mainSchool } from 'Helpers/const/AppData'
import { isLocalNetwork } from 'Helpers/const/isLocalNetwork'
import { getSearchParam } from 'Helpers/searchParams'

export default {
  register({ state }) {
    const payload = {
      ...state.registerData,

      registrationDomain: window.domains.main,
      fromShowcase: false,
    }

    const targetUrl = getSearchParam('targetUrl')

    if (targetUrl !== null) {
      payload.targetUrl = targetUrl
    }

    return ApiRegistration.post(payload)
  },

  async fetchSchoolPlatform({ commit }) {
    let { id } = mainSchool

    if (!isLocalNetwork()) {
      const domain = window.domains.main
      const { data: subdomainsList = [] } = await ApiSubdomainList.get(domain)

      if (subdomainsList.length > 0) {
        const school = subdomainsList.find((subdomain) => subdomain.self)

        if (school) {
          id = school.schoolId
        }
      }
    }

    const { data } = await ApiSchool.get(id)

    commit('setSchoolPlatform', data)
  },

  async confirmEmail(context, code) {
    return ApiConfirmEmail.patch({
      code,
    })
  },

  sendConfirmEmail(context, payload) {
    return ApiSendConfirmEmail.post(payload)
  },

  async fetchFormEducation({ commit }) {
    const { data } = await ApiOptionsDemoEducationTypes.get()

    commit('setEducationTypes', data)
  },
}
