export const clientMainRoutes = [
  {
    path: '',
    name: 'client-main',
    component: () =>
      import(
        /* webpackChunkName: "client-main" */
        '@frontend/pages/client/PageMain.vue'
      ),
  },
  {
    path: 'contracts',
    name: 'client-contracts',
    component: () =>
      import(
        /* webpackChunkName: "client-contracts" */
        '@frontend/pages/client/Contracts.vue'
      ),
  },
  {
    path: 'demo-access',
    name: 'client-demo-access',
    component: () =>
      import(
        /* webpackChunkName: "client-demo-access" */
        '@frontend/pages/client/DemoAccess.vue'
      ),
  },
  {
    path: 'contracts/:id(\\d+)',
    name: 'client-contract',
    component: () =>
      import(
        /* webpackChunkName: "client-contract" */
        '@frontend/pages/client/TheContract.vue'
      ),
  },
  {
    path: 'requests/new',
    name: 'client-request-new',
    component: () =>
      import(
        /* webpackChunkName: "client-request-new" */
        '@frontend/pages/client/TheRequestDraft.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: 'requests-simplified/new',
    name: 'client-request-simplified',
    component: () =>
      import(
        /* webpackChunkName: "client-request-simplified" */
        '@frontend/pages/client/TheRequestSimplified.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: 'requests/drafts/:id(\\d+)',
    name: 'client-request-draft',
    component: () =>
      import(
        /* webpackChunkName: "client-request-draft" */
        '@frontend/pages/client/TheRequestDraft.vue'
      ),
  },
  {
    path: 'requests/:id(\\d+)',
    name: 'client-request',
    component: () =>
      import(
        /* webpackChunkName: "client-request" */
        '@frontend/pages/client/TheRequest.vue'
      ),
  },
  {
    path: 'profile',
    name: 'client-profile',
    component: () =>
      import(
        /* webpackChunkName: "client-profile" */
        '@frontend/pages/client/Profile.vue'
      ),
  },
  {
    path: 'calendar',
    name: 'client-calendar',
    component: () =>
      import(
        /* webpackChunkName: "client-calendar" */
        '@frontend/pages/client/Calendar.vue'
      ),
  },
  {
    path: 'calendar/:day([\\d\\-]{10})',
    name: 'client-calendar-day',
    component: () =>
      import(
        /* webpackChunkName: "client-calendar-day" */
        '@frontend/pages/client/Calendar.vue'
      ),
  },
  {
    path: 'events',
    name: 'client-events',
    component: () =>
      import(
        /* webpackChunkName: "client-events" */
        '@frontend/pages/client/Events.vue'
      ),
  },
  {
    path: 'events/:eventId(\\d+)',
    name: 'client-event',
    component: () =>
      import(
        /* webpackChunkName: "client-event" */
        '@frontend/pages/client/TheEvent.vue'
      ),
  },
  {
    path: 'goals',
    name: 'client-goals',
    component: () =>
      import(
        /* webpackChunkName: "client-goals" */
        '@frontend/pages/client/Goals.vue'
      ),
  },
  {
    path: 'lessons',
    name: 'client-lessons',
    component: () =>
      import(
        /* webpackChunkName: "client-lessons" */
        '@frontend/pages/client/Lessons.vue'
      ),
  },
  {
    path: 'news',
    name: 'client-news',
    component: () =>
      import(
        /* webpackChunkName: "client-news" */
        '@frontend/pages/client/News.vue'
      ),
  },
  {
    path: 'news/:newsArticleId(\\d+)',
    name: 'client-news-article',
    component: () =>
      import(
        /* webpackChunkName: "client-news-article" */
        '@frontend/pages/client/TheNewsArticle.vue'
      ),
  },
  {
    path: 'statements',
    name: 'client-statements',
    component: () =>
      import(
        /* webpackChunkName: "client-statements" */
        '@frontend/pages/client/Statements.vue'
      ),
  },
  {
    path: 'statements/:groupId(\\d+)',
    name: 'client-statement',
    component: () =>
      import(
        /* webpackChunkName: "client-statement" */
        '@frontend/pages/client/TheStatement.vue'
      ),
  },
  {
    path: 'certification',
    name: 'client-certification',
    component: () =>
      import(
        /* webpackChunkName: "client-certification" */
        '@frontend/pages/client/Certification.vue'
      ),
  },
  {
    path: 'request/family-certification/new',
    name: 'client-the-family-certification-request-new',
    props: {
      isCreate: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "client-the-family-certification-request" */
        '@frontend/pages/client/TheFamilyCertificationRequest.vue'
      ),
  },
  {
    path: 'request/family-certification/edit',
    name: 'client-the-family-certification-request-edit',
    component: () =>
      import(
        /* webpackChunkName: "client-the-family-certification-request" */
        '@frontend/pages/client/TheFamilyCertificationRequest.vue'
      ),
  },
  {
    path: 'request/family-certification/docs',
    name: 'client-the-family-certification-request-docs',
    component: () =>
      import(
        /* webpackChunkName: "client-the-family-certification-request" */
        '@frontend/pages/client/TheFamilyCertificationRequestDocs.vue'
      ),
  },
  {
    path: 'notifications',
    name: 'client-notifications',
    component: () =>
      import(
        /* webpackChunkName: "client-notifications" */
        '@frontend/pages/common/Notifications.vue'
      ),
  },
  {
    path: 'messages',
    name: 'client-messages',
    component: () =>
      import(
        /* webpackChunkName: "client-messages" */
        '@frontend/pages/common/Messages.vue'
      ),
  },
  {
    path: 'payment/success',
    name: 'client-payment-success',
    component: () =>
      import(
        /* webpackChunkName: "client-payment-success" */
        '@frontend/pages/client/TheInvoice.vue'
      ),
    props: {
      isModalOpenSuccess: true,
    },
  },
  {
    path: 'payment/error',
    name: 'client-payment-error',
    component: () =>
      import(
        /* webpackChunkName: "client-payment-error" */
        '@frontend/pages/client/TheInvoice.vue'
      ),
    props: {
      isModalOpenError: true,
    },
  },
  {
    path: 'schedule-lessons',
    name: 'client-schedule-lessons',
    component: () =>
      import(
        /* webpackChunkName: "client-schedule-lessons" */
        '@frontend/pages/client/ScheduleLessons.vue'
      ),
  },
  {
    path: 'income-diagnostics',
    name: 'client-income-diagnostics',
    component: () =>
      import(
        /* webpackChunkName: "client-income-diagnostics" */
        '@frontend/pages/client/IncomeDiagnostics.vue'
      ),
  },
  {
    path: 'library',
    name: 'client-library',
    component: () =>
      import(
        /* webpackChunkName: "client-library" */
        '@frontend/pages/client/Library.vue'
      ),
  },
]
