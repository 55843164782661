import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseOnlineLessonList = new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    const url = `/showcase/materials/online-lessons?${this.getUrlParams(
      parameters,
    )}`

    return this._GET(url)
  }
})()
