import { StoreUser } from '@frontend/StoreUser'
import authHelper from '@frontend/helpers/auth'
import { scrollBehavior } from '@frontend/helpers/scrollBehavior'
import NotFound from '@frontend/pages/NotFound.vue'
import { authRoutes } from '@frontend/routes/auth'
import { clientRoutes } from '@frontend/routes/client'
import { previewRoutes } from '@frontend/routes/preview'
import { studentRoutes } from '@frontend/routes/student'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import { ApiEducontAuth } from 'ApiRest/Api/Educont/Auth'
import { ApiUserRedirect } from 'ApiRest/Api/UserRedirect'
import SentryHelper from 'Helpers/SentryHelper'
import { authUrl } from 'Helpers/const/authUrl'
import { isMobileDomain } from 'Helpers/domain'
import { promiseTimeout } from 'Helpers/promiseTimeout'
import { redirectToAdminArea, redirectToAuth } from 'Helpers/redirectHelper'
import { showToast } from 'Helpers/toast'
import { trackPage } from 'Helpers/trackPage'
import VueRouter from 'vue-router'

export const RouterUserNew = new (class {
  constructor() {
    this.router = null
  }

  async init() {
    const isClient = () => StoreUser.store.getters['common/user/isClient']
    const isStudent = () => StoreUser.store.getters['common/user/isStudent']
    const isManager = () => StoreUser.store.getters['common/user/isManager']
    const isDemoUser = () => StoreUser.store.getters['common/user/isDemoUser']
    const isAuthorized = () =>
      StoreUser.store.getters['common/user/isAuthorized']
    const hasAvailablePayment = () =>
      StoreUser.store.getters['student/profile/hasAvailablePayment'] ?? false
    const isStudentDemoFeSimple = () =>
      StoreUser.store.state.student.profile.profile.isSimpleFeDemo ?? false

    await StoreUser.store.dispatch('common/user/fetchIsAuthorized', null, {
      root: true,
    })

    if (isStudent()) {
      await StoreUser.store.dispatch('student/profile/fetchProfile', null, {
        root: true,
      })
    }

    if (isClient()) {
      await StoreUser.store.dispatch('client/profile/fetchProfile', null, {
        root: true,
      })
    }

    /**
     * Открыть раздел клиента
     * @param {Function} next
     * @returns {Function}
     */
    const displayClientSection = (next) =>
      next({
        name: 'client-main',
      })

    /**
     * Открыть раздел ученика
     * @param {Function} next
     * @returns {Function}
     */
    const displayStudentSection = (next) =>
      next({
        name: 'student-main',
      })

    const reloadPage = () => window.location.reload()

    const checkLoggedInUser = () => {
      const userId = StoreUser.store.state.common.user.id
      const clientId = StoreUser.store.state.client.profile.profile.id
      const studentId = StoreUser.store.state.student.profile.profile.id

      if (!userId) {
        return false
      }

      if (isClient() && clientId !== userId) {
        reloadPage()
      }

      if (isStudent() && studentId !== userId) {
        reloadPage()
      }

      return true
    }

    const setTargetUrl = (to) => {
      if (to.name === 'login' || to.name === 'logout') {
        return window.location.origin
      }

      return window.location.href
    }

    const processBeforeEach = async (to, from, next) => {
      const targetUrl = setTargetUrl(to)

      if (isAuthorized()) {
        SentryHelper.setUser({
          id: StoreUser.store.state.common.user.id,
          email: StoreUser.store.state.common.user.email,
          baseRole: StoreUser.store.state.common.user.baseRole,
        })
      } else {
        SentryHelper.resetUser()
      }

      if (to.query.smartcode) {
        try {
          const params = {
            smartcode: to.query.smartcode,
          }

          const { status } = await ApiEducontAuth.get(params)

          if (status === HttpStatusCodes.Accepted) {
            return next({
              name: 'educont-registration',
              params,
            })
          }

          if (to.name === 'educont-invite') {
            return displayStudentSection(next)
          }
        } catch (error) {
          redirectToAuth(authUrl.login, targetUrl)

          return next(false)
        }
      }

      // Если переход на страницу login/logout произошел через роутинг (router-link, router.push()),
      // то делать редирект на authUrl, так как это внешние страницы (микросервис авторизации)

      if (to.name === 'login') {
        redirectToAuth(authUrl.login, targetUrl)

        return next(false)
      }

      if (to.name === 'logout') {
        redirectToAuth(authUrl.logout, targetUrl, to.query.email)

        return next(false)
      }

      if (to.name === 'password-reset') {
        redirectToAuth(authUrl.recover)

        return next(false)
      }

      if (!isAuthorized()) {
        // Если страница регистрации, пропустить
        if (authHelper.auth.isAuthPage(to.name)) {
          return next()
        }

        redirectToAuth(authUrl.login, targetUrl)

        return next(false)
      }

      if (isManager()) {
        if (isMobileDomain) {
          showToast('Мобильный поддомен недоступен для менеджеров', 'error')

          await promiseTimeout()

          redirectToAuth(authUrl.logout)

          return next(false)
        }

        // Если менеджер посещает роут "предпросмотра", то пропустить
        if (authHelper.auth.isPreview(to.name)) {
          return next()
        }

        if (to.path === '/' && to.query.chat) {
          return redirectToAdminArea(`messages?chat=${to.query.chat}`)
        }

        return redirectToAdminArea()
      }

      if (
        from === VueRouter.START_LOCATION ||
        authHelper.auth.isAuthPage(to.name)
      ) {
        if (isStudent()) {
          if (!isMobileDomain) {
            const { data: urlRedirect } = await ApiUserRedirect.get()

            const schoolName =
              StoreUser.store.state.student.profile.profile.school.name

            if (urlRedirect) {
              showToast(
                `Вы вошли в систему под учеником школы "${schoolName}"` +
                  `и будете перенаправлены на её платформу`,
              )

              await promiseTimeout()

              window.location.href = urlRedirect
            }
          }
        }
      }

      checkLoggedInUser()

      const { grade } = StoreUser.store.state.student.profile.profile

      if (to.name !== 'demo-fe-simple-grade-select') {
        if (isStudentDemoFeSimple() && !grade) {
          return next({
            name: 'demo-fe-simple-grade-select',
          })
        }
      }

      if (to.name === 'demo-fe-simple-grade-select') {
        if (isStudentDemoFeSimple() && !grade) {
          return next()
        }

        return next(false)
      }

      if (to.name === 'student-payment') {
        if (hasAvailablePayment()) {
          return next()
        }

        if (isClient()) {
          return displayClientSection(next)
        }

        if (isStudent()) {
          return displayStudentSection(next)
        }
      }

      if (to.path === '/' && to.query.chat) {
        if (isClient()) {
          return next({
            name: 'client-messages',
            query: to.query,
          })
        }

        if (isStudent()) {
          return next({
            name: 'student-messages',
            query: to.query,
          })
        }
      }

      if (to.path !== '/') {
        if (
          isDemoUser() &&
          authHelper.auth.isDemoUserAccessForbidden(to.name)
        ) {
          return next(false)
        }

        return next()
      }

      if (isClient()) {
        return displayClientSection(next)
      }

      if (isStudent()) {
        return displayStudentSection(next)
      }

      redirectToAuth(authUrl.login, targetUrl)

      return next(false)
    }

    const processAfterEach = async (to, from) => {
      if (from !== VueRouter.START_LOCATION) {
        const event = new Event('pageChange')

        document.dispatchEvent(event)
      }

      if (isStudent()) {
        await trackPage(to, from)
      }
    }

    this.router = new VueRouter({
      mode: 'history',

      routes: [
        ...authRoutes,
        ...clientRoutes,
        ...studentRoutes(),
        ...previewRoutes,

        {
          path: '*',
          name: 'not-found',
          component: NotFound,
        },
      ],

      scrollBehavior,
    })

    this.router.beforeEach(processBeforeEach)
    this.router.afterEach(processAfterEach)

    return this.router
  }
})()
