<template>
  <div class="b-white py-2 py-sm-4">
    <Slider :slides="slides" :breakpoints="breakpoints" />
  </div>
</template>

<script>
import Slider from '@frontend/components/showcase/common/slider/Slider.vue'
import { isWindowWidthDesktop } from 'Helpers/WindowWidthHelper'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BannersBlock',

  components: {
    Slider,
  },

  props: {
    banners: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    slides() {
      return this.banners.map((banner) => ({
        image: isWindowWidthDesktop()
          ? banner.imageDesktopVersion
          : banner.imageMobileVersion,
        url: banner.link,
      }))
    },

    breakpoints() {
      return {
        mobile: 770,
        tablet: 1088,
      }
    },
  },
})
</script>
