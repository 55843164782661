import createState from '@frontend/store/showcase/common/home/state'
import { RESET_STATE } from 'Helpers/ResetState'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setBanners(state, payload) {
    state.banners = payload
  },

  setProductsLatest(state, payload) {
    state.productsLatest = payload
  },

  setProductsPopular(state, payload) {
    state.productsPopular = payload
  },

  setSeo(state, payload) {
    state.seo = payload
  },

  setNavigationBanner(state, payload) {
    state.navigationBanner = payload
  },
}
