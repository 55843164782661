import { RESET_STATE } from 'Helpers/ResetState'

import Vue from 'vue'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setInputValue(state, { key, value }) {
    Vue.set(state.registerData, key, value)
  },

  setSchoolPlatform(state, payload) {
    state.schoolPlatform = payload
  },

  setEducationTypes(state, payload) {
    state.educationTypes = payload
  },
}
