import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setNews(state, payload) {
    state.news = payload
  },

  pushNews(state, payload) {
    state.news = [
      ...state.news,
      ...payload,
    ]
  },

  setNewsArticle(state, payload) {
    state.newsArticle = payload
  },

  setTags(state, payload) {
    state.tags = payload
  },

  setNewsPaginationCurrentPage(state, payload) {
    state.newsPaginationPage = payload
  },

  setNewsPaginationPageCount(state, payload) {
    state.newsPaginationPagesTotal = payload
  },

  setActiveTag(state, payload) {
    state.activeTag = payload
  },
}
