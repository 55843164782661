<template>
  <header ref="header" class="s-header-wrapper" :class="cssClass.headerWrapper">
    <div v-if="isRendered.noticeText" class="s-header__notice">
      <span>
        <template v-if="noticeText">
          {{ noticeText }}
        </template>

        <span class="notice-text--thin">
          <router-link
            v-if="isRendered.routeTo.payment"
            :to="routeToPayment"
            rel="noopener noreferrer"
            class="text-underline text-dark"
          >
            {{ noticeAdditionalMessage }}
          </router-link>

          <template v-else>
            {{ noticeAdditionalMessage }}
          </template>
        </span>
      </span>
    </div>

    <div class="s-header">
      <router-link class="s-logo s-header__logo" :to="routeToMain">
        <h2 v-if="!isSchoolEducont" class="s-school-name">{{ schoolName }}</h2>

        <div v-else class="s-school-name">
          <img class="s-logo-educont" :src="logos.educont" alt="logo educont" />
        </div>

        <img :src="logos.default" :alt="t('logotype')" />
      </router-link>

      <div class="s-header__profile">
        <ElAvatar
          :abbreviation="abbreviation"
          :image="avatar"
          :class="cssClass.abbr"
        />

        <div class="text">
          <span class="name">{{ fullName }}</span>

          <span v-if="isRendered.gradeName" class="grade">
            {{ gradeName }}
          </span>
        </div>

        <!-- TODO: i18n -->
        <v-select
          v-if="isRendered.gradeSelect"
          class="v-select-student v-select-grade ml-3"
          label="abbr"
          placeholder="Выберите класс"
          :clearable="false"
          :searchable="false"
          :options="gradeOptions"
          :value="selectedGrade"
          @input="onInputGrade"
        >
          <template #selected-option="option">
            <div class="s-header__student">
              <div class="text">
                <span class="name">
                  {{ t('select_class') }}
                </span>

                <span class="grade">{{ option.gradeName }}</span>
              </div>
            </div>
          </template>

          <template #option="option">
            <div class="s-header__student-option">
              <b class="name">{{ option.gradeName }}</b>
            </div>
          </template>
        </v-select>
      </div>

      <template v-if="isRendered.switchToClient">
        <ElButton
          :label="t('go_to_client_account')"
          class="btn btn-outline-light ml-4 d-none d-lg-block"
          @click="onClickBtnSwitchToClient"
        />

        <ModalClientPassword ref="modalClientPassword" />
      </template>

      <div v-if="isRendered.rating" class="s-header__rating-desktop-display">
        <TheHeaderRating :rating="rating" />
      </div>

      <div v-if="isRendered.credit" class="s-header__rating-desktop-display">
        <TheHeaderCredit :credit="credit" />
      </div>

      <nav class="s-header__nav">
        <LanguageSelect
          v-if="isRendered.langSelect"
          v-model="lang"
          :options="langOptionsAvailable"
          class="v-select-language mr-2"
        />

        <router-link
          v-if="isRendered.routeTo.notifications"
          :to="linkNotifications.link"
        >
          <img :src="icons.bell" :alt="t('notification_icon')" />

          <i v-if="isRendered.notifications.notifications" class="notif">
            {{ linkNotifications.notifications }}
          </i>
        </router-link>

        <router-link v-if="isRendered.routeTo.calendar" :to="linkCalendar.link">
          <img :src="icons.calendar" :alt="t('calendar_icon')" />

          <i v-if="isRendered.notifications.calendar" class="notif">
            {{ linkCalendar.notifications }}
          </i>
        </router-link>

        <router-link
          v-if="isRendered.routeTo.messages"
          :to="linkMessages.link"
          class="ml-20"
        >
          <img :src="icons.message" :alt="t('message_icon')" />

          <i v-if="isRendered.notifications.messages" class="notif">
            {{ linkMessages.notifications }}
          </i>
        </router-link>

        <router-link v-if="isRendered.routeTo.profile" :to="routeToProfile">
          <img :src="icons.settings" :alt="t('settings_icon')" />
        </router-link>

        <router-link :to="routeToLogout" class="wide">
          <img :src="icons.exit" :alt="t('exit_icon')" />
        </router-link>
      </nav>

      <button
        class="s-mobile-menu__toggle"
        :class="{ opened }"
        @click="toggleMobileMenu"
      >
        <span v-for="i in 3" :key="i"></span>
      </button>

      <transition name="fade">
        <div v-if="opened" class="s-mobile-menu" :style="mobileMenuTopOffset">
          <div v-if="isRendered.switchToClient" class="block">
            <div class="link link-dark" @click="onClickBtnSwitchToClient">
              {{ t('go_to_client_account') }}
            </div>
          </div>

          <div class="block">
            <TheHeaderMenuLink
              v-for="link in links"
              :key="link.link.name"
              :link="link"
            />
          </div>

          <div class="block">
            <LanguageSelect
              v-if="isRendered.langSelect"
              v-model="lang"
              :options="langOptionsAvailable"
              icon-dark
              class="link"
            />

            <router-link
              v-if="isRendered.routeTo.profile"
              :to="routeToProfile"
              class="link link-dark"
            >
              {{ t('settings') }}
            </router-link>

            <router-link :to="routeToLogout" class="link link-dark">
              {{ t('log_off') }}
            </router-link>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="rating" class="s-header__rating-mobile-tablet-display">
      <TheHeaderRating :rating="rating" />
    </div>
  </header>
</template>

<script>
import ModalClientPassword from '@frontend/components/common/ModalClientPassword.vue'
import TheHeaderCredit from '@frontend/components/common/TheHeaderCredit.vue'
import TheHeaderRating from '@frontend/components/common/TheHeaderRating.vue'
import TheHeaderMenuLink from '@frontend/layouts/common/TheHeaderMenuLink.vue'
import ElAvatar from '@frontend/ui/ElAvatar.vue'
import ElButton from '@frontend/ui/ElButton.vue'
import HeaderMixin from 'Helpers/mixins/layout/HeaderMixin'
import iconBell from 'Static/images/@frontend/common-lk/icons/bell.svg'
import iconCalendar from 'Static/images/@frontend/common-lk/icons/calendar.svg'
import iconExit from 'Static/images/@frontend/common-lk/icons/exit.svg'
import iconMessage from 'Static/images/@frontend/common-lk/icons/message.svg'
import iconSettings from 'Static/images/@frontend/common-lk/icons/settings.svg'
import logoEducont from 'Static/images/@frontend/common-lk/logo-educont.svg'
import logoDefault from 'Static/images/@frontend/common-lk/logo.svg'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import LanguageSelect from '@frontend/components/common/LanguageSelect.vue'
import { LangSwitchModuleMixin } from '@frontend/mixins/LangSwitchModuleMixin'

export default {
  name: 'TheHeaderStudent',

  components: {
    LanguageSelect,
    TheHeaderMenuLink,
    ModalClientPassword,
    ElButton,
    ElAvatar,
    TheHeaderRating,
    TheHeaderCredit,
  },

  mixins: [
    HeaderMixin,
    LangSwitchModuleMixin,
  ],

  props: {
    links: {
      type: Array,
      required: true,
    },

    fullName: {
      type: String,
      default: '',
    },

    abbreviation: {
      type: String,
      default: '',
    },

    avatar: {
      type: String,
      default: '',
    },

    grade: {
      type: Number,
      default: null,
    },

    letter: {
      type: String,
      default: '',
    },

    schoolName: {
      type: String,
      default: '',
    },

    hasBlockingCertifications: {
      type: Boolean,
      required: true,
    },

    noticeText: {
      type: String,
      default: '',
    },

    noticeAdditionalMessage: {
      type: String,
      default: '',
    },

    rating: {
      type: Object,
      default: null,
    },

    credit: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      logos: {
        default: logoDefault,
        educont: logoEducont,
      },

      icons: {
        calendar: iconCalendar,
        settings: iconSettings,
        message: iconMessage,
        bell: iconBell,
        exit: iconExit,
      },

      grades: [],
    }
  },

  computed: {
    ...mapState('student/profile', [
      'profile',
      'educontGrades',
      'selectedGrade',
    ]),

    ...mapGetters('common/user', [
      'isDemoUser',
    ]),

    ...mapGetters('student/profile', [
      'onlyDiagnostics',
      'educationType',
    ]),

    hasGrade() {
      return Boolean(this.grade)
    },

    isEducont() {
      return Boolean(this.profile.school?.isEducont)
    },

    isSchoolEducont() {
      return this.profile.school?.id === 31
    },

    gradeName() {
      return `${this.grade}${this.letter ? `-${this.letter}` : ''} ${this.t(
        'grade_ls',
      )}`
    },

    gradeOptions() {
      return this.grades.map((grade) => this.getGradeOption(grade))
    },

    linkNotifications() {
      return this.getLinkByName('notifications')
    },

    linkCalendar() {
      return this.getLinkByName('calendar')
    },

    linkMessages() {
      return this.getLinkByName('student-messages')
    },

    routeToProfile() {
      return {
        name: 'student-profile',
      }
    },

    routeToPayment() {
      return {
        name: 'student-payment',
      }
    },

    routeToLogout() {
      return {
        name: 'logout',
      }
    },

    routeToMain() {
      if (this.hasBlockingCertifications) {
        return {
          name: 'certification',
        }
      }

      if (this.onlyDiagnostics) {
        return {
          name: 'student-income-diagnostics',
        }
      }

      return '/'
    },

    cssClass() {
      return {
        headerWrapper: {
          noticed: this.isRendered.noticeText,
        },

        abbr: this.isRendered.gradeSelect
          ? [
              'd-none',
              'd-sm-flex',
            ]
          : '',
      }
    },

    isRendered() {
      return {
        routeTo: {
          notifications: !!this.linkNotifications,
          calendar: !!this.linkCalendar,
          messages: !!this.linkMessages,
          profile: this.canAccessProfile,
          payment:
            this.educationType.family &&
            (this.profile.isDemo || this.profile.hasAwaitingPayment),
        },

        notifications: {
          notifications: this.linkNotifications?.notifications > 0,
          calendar: this.linkCalendar?.notifications > 0,
          messages: this.linkMessages?.notifications > 0,
        },

        noticeText: !!this.noticeText,
        gradeName: this.hasGrade && !this.isEducont,
        gradeSelect: this.isEducont,
        langSelect: this.isAvailableLangSwitch && !!this.lang,
        switchToClient: this.profile.hasClient,

        rating: this.rating !== null,
        credit: this.credit !== null,
      }
    },

    /**
     * @returns {boolean}
     */
    canAccessProfile() {
      return !this.isDemoUser && !this.isEducont
    },
  },

  watch: {
    noticeText() {
      this.setHeightNoticedMobileMenu()
    },
  },

  created() {
    this.init()
  },

  methods: {
    ...mapMutations('student/profile', [
      'setSelectedGrade',
    ]),

    ...mapActions('student/profile', [
      'updateLang',
      'updateGrade',
      'fetchEducontGrades',
    ]),

    async init() {
      if (this.isEducont) {
        await this.fetchEducontGrades()

        this.grades = this.educontGrades
      } else {
        this.grades = this.profile.school?.grades
      }

      this.initGradeSelectedOption()
    },

    initGradeSelectedOption() {
      this.setGradeOptionSelected(this.profile.grade)
    },

    getLinkByName(name) {
      return this.links.find(({ link }) => link.name === name) ?? null
    },

    getGradeOption(grade) {
      return {
        ...grade,
        abbr: grade.id,
        gradeName: `${grade.name} класс`, // TODO: i18n
      }
    },

    setGradeOptionSelected(optionName) {
      const grade = this.grades.find(({ name }) => name === optionName)

      if (grade) {
        this.setSelectedGrade(this.getGradeOption(grade))
      }
    },

    async onInputGrade(option) {
      const optionPrev = this.selectedGrade

      this.setGradeOptionSelected(option.name)

      try {
        await this.updateGrade(option.id)
        this.$emit('change-grade')
      } catch (error) {
        this.setGradeOptionSelected(optionPrev.name)

        // TODO: i18n
        this.$toasted.error('Не удалось сменить класс', {
          duration: 3500,
        })
      }
    },

    onClickBtnSwitchToClient() {
      this.$refs.modalClientPassword.show()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`layout.common.${name}`)
    },
  },
}
</script>
