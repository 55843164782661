import cart from '@frontend/store/showcase/common/cart/store'
import catalog from '@frontend/store/showcase/common/catalog/store'
import category from '@frontend/store/showcase/common/category/store'
import collection from '@frontend/store/showcase/common/collection/store'
import home from '@frontend/store/showcase/common/home/store'
import lessons from '@frontend/store/showcase/common/lessons/store'
import lessonsOnline from '@frontend/store/showcase/common/lessonsOnline/store'
import locale from '@frontend/store/showcase/common/locale/store'
import notifications from '@frontend/store/showcase/common/notifications/store'
import product from '@frontend/store/showcase/common/product/store'
import purchase from '@frontend/store/showcase/common/purchase/store'
import subjects from '@frontend/store/showcase/common/subjects/store'
import support from '@frontend/store/showcase/common/support/store'
import test from '@frontend/store/showcase/common/test/store'
import user from '@frontend/store/showcase/common/user/store'

export default {
  namespaced: true,

  modules: {
    cart,
    catalog,
    category,
    collection,
    home,
    lessons,
    lessonsOnline,
    locale,
    notifications,
    product,
    purchase,
    subjects,
    support,
    test,
    user,
  },
}
