import authHelper from '@frontend/helpers/auth'
import { filterNavLinks } from '@frontend/helpers/filterNavLinks'
import { isMobileDomain } from 'Helpers/domain'
import { feature } from 'Helpers/feature'
import { isEmpty } from 'lodash'

/** Ссылки навигации, доступные для ученика, если есть блокирующие аттестации */
const linksAvailableWhenHasBlockingCertifications = [
  'certification',
]

/** Ссылки навигации для Заочного обучения */
const linksForExtramuralEducation = [
  'student-information',
  'student-lessons-group',
  'student-calendar-group',

  'subjects',
  'lessons',
  'online-lessons',
  'student-statements',
  'certification',
  'calendar',
  'goals',
  'notifications',
  'student-news',
  'student-library',
  'student-support',
  'student-showcase',
  'student-courses',
  'student-messages',
  'student-income-diagnostics',
]

/** Ссылки навигации для Семейного обучения */
const linksForFamilyEducation = [
  'student-learn-group',

  'student-main',
  'subjects',
  'student-statements',
  'certification',
  'calendar',
  'notifications',
  'student-showcase',
  'student-courses',
  'student-messages',
  'student-income-diagnostics',
]

/** Ссылки навигации для Очного обучения */
const linksForFullTimeEducation = [
  'subjects',
  'online-lessons',
  'student-statements',
  'certification',
  'calendar',
  'goals',
  'notifications',
  'student-news',
  'student-library',
  // TODO: temporary hide link. https://redmine.ibls.tech/issues/4139. Согласовано с Ушаковым
  // 'student-events',
  'schedule-tests',
  'schedule-lessons',
  'student-support',
  'student-showcase',
  'student-courses',
  'student-messages',
  'student-income-diagnostics',
]

/** Ссылки навигации, доступные для ЦОК */
const linksAvailableWhenSchoolIsEducont = [
  'subjects',
  'lessons',
  'calendar',
  'goals',
  'notifications',
  'student-showcase',
]

const linksExcludedOnMobile = [
  // TODO: temporary hide link. https://redmine.ibls.tech/issues/4139. Согласовано с Ушаковым
  // 'student-events',
]

// TODO: убрать хардкодные id

/**
 * Список ID основных школ
 */
const mainSchoolIds = [
  1,
  4,
  3,
]

/**
 * Список ссылок навигации, доступных для основных школ
 */
const linksAvailableWhenSchoolIsMain = [
  // TODO: temporary hide link. https://redmine.ibls.tech/issues/4139. Согласовано с Ушаковым
  // 'student-events',
  'student-library',
]

const linksCommercialOffers = [
  'student-showcase',
  'student-courses',
]

/**
 * Список ссылок навигации, доступных для школ по id
 */
const linksAvailableBySchoolId = {
  37: [
    'student-library',
  ],
}

export default {
  getLinks(state, getters, rootState, rootGetters) {
    const currentSchoolId = rootState.student.profile.profile.school.id
    const isEducontCurrentSchool =
      rootState.student.profile.profile?.school?.isEducont ?? false
    const isMainCurrentSchool = mainSchoolIds.includes(currentSchoolId)
    const hasBlockingCertifications =
      rootGetters['student/extramuralCertification/hasBlockingCertifications']
    const educationType = rootGetters['student/profile/educationType']
    const familyCertifications =
      rootState.student.familyCertification.certifications
    const extramuralCertifications =
      rootGetters['student/extramuralCertification/getAvailableCertifications']
    const fullTimeCertifications =
      rootGetters['student/fullTimeCertification/getAvailableCertifications']
    const isDemoUser = rootGetters['common/user/isDemoUser']
    const hasCertifications = [
      extramuralCertifications,
      familyCertifications,
      fullTimeCertifications,
    ].some((certifications) => certifications.length > 0)
    const hasModules = rootGetters['common/module/hasModules']
    const hasIncomeDiagnostics =
      rootState.student.profile.profile?.hasIncomeDiagnostics ?? false
    const onlyDiagnostics =
      rootState.student.profile.profile?.onlyDiagnostics ?? false
    const isAvailableAnyCommercialOffer =
      rootGetters['student/profile/isAvailableAnyCommercialOffer']

    const linksAvailableForCurrentSchool =
      linksAvailableBySchoolId[currentSchoolId] ?? []

    const getNavLinks = () => {
      if (educationType.extramural) {
        return state.linksExtramural
      }

      if (educationType.family) {
        return state.linksFamily
      }

      return state.links
    }

    let navLinks = getNavLinks()

    // Если используется мобильная версия
    if (isMobileDomain) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => !linksExcludedOnMobile.includes(link.link.name),
      )
    }

    // Если отключены коммерческие предложения
    if (!isAvailableAnyCommercialOffer) {
      navLinks = navLinks.filter(
        (link) => !linksCommercialOffers.includes(link.link.name),
      )
    }

    // Если отключена входящая диагностика
    if (
      !feature.isIncomeDiagnostics ||
      (!hasIncomeDiagnostics && !onlyDiagnostics) ||
      (feature.isIncomeDiagnostics && onlyDiagnostics)
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-income-diagnostics',
      )
    }

    if (educationType.extramural) {
      navLinks = filterNavLinks(navLinks, (link) =>
        linksForExtramuralEducation.includes(link.link.name),
      )
    }

    if (educationType.family) {
      navLinks = filterNavLinks(navLinks, (link) => {
        if (
          link.link.name === 'student-statements' &&
          isEmpty(rootGetters['student/subjects/getSubjectsExtracurricular'])
        ) {
          return false
        }

        return linksForFamilyEducation.includes(link.link.name)
      })
    }

    if (educationType.fullTime) {
      navLinks = filterNavLinks(navLinks, (link) =>
        linksForFullTimeEducation.includes(link.link.name),
      )
    }

    // Если школа Educont
    if (isEducontCurrentSchool) {
      navLinks = filterNavLinks(navLinks, (link) =>
        linksAvailableWhenSchoolIsEducont.includes(link.link.name),
      )
    }

    // Если есть блокирующие аттестации
    if (hasBlockingCertifications) {
      navLinks = filterNavLinks(navLinks, (link) =>
        linksAvailableWhenHasBlockingCertifications.includes(link.link.name),
      )
    }

    // Если школы нет в списке mainSchoolIds
    if (!isMainCurrentSchool) {
      navLinks = filterNavLinks(
        navLinks,
        (link) =>
          linksAvailableForCurrentSchool.includes(link.link.name) ||
          !linksAvailableWhenSchoolIsMain.includes(link.link.name),
      )
    }

    // Если не подключен модуль "Онлайн-уроки"
    if (
      !hasModules([
        'online-lesson',
      ])
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'online-lessons',
      )
    }

    // Скрыть запрещенные разделы для ученика с демо-доступом
    if (isDemoUser) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => !authHelper.auth.isDemoUserAccessForbidden(link.link.name),
      )
    }

    // Если нет аттестаций
    if (!hasCertifications) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'certification',
      )
    }

    // Если не подключен модуль "Сообщения"
    if (
      !hasModules([
        'chat',
      ])
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-messages',
      )
    }

    // Если не подключен модуль "Поддержка"
    if (
      !hasModules([
        'support',
      ])
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-support',
      )
    }

    // Если не подключен модуль "Витрина"
    if (
      !hasModules([
        'educational-showcase',
      ])
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-showcase',
      )
    }

    // Если подключен модуль "Библиотека"
    if (
      !hasModules([
        'school-library',
      ])
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-library',
      )
    }

    // Если подключен модуль "Курсы"
    if (
      !hasModules([
        'educational-courses',
      ])
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-courses',
      )
    }

    return navLinks
  },
}
