import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setStatements(state, payload) {
    state.statements = payload
  },

  setTheStatement(state, payload) {
    state.statement = payload
  },

  setPeriodList(state, payload) {
    state.periodList = payload
  },

  setPeriod(state, payload) {
    state.period = payload
  },

  setPromoCtps(state, payload) {
    const firstStatement = state.statements[0]
    const promoCtps = payload.map((item) => ({
      ...item,
      isActual: true,
      isPromo: true,
    }))

    Vue.set(state.statements, 0, {
      ...firstStatement,
      items: [
        ...firstStatement.items,
        ...promoCtps,
      ],
    })
  },
}
