import { i18n } from '@/i18n/setup'
import { isNil } from 'lodash'

/**
 * @typedef {Object} statementsFilterOption
 * @property {string} name - наименование
 * @property {number} id - идентификатор
 */

export class StatementsFilter {
  constructor() {
    /**
     * @type {statementsFilterOption[]}
     */
    this.options = [
      {
        id: 1,
        name: i18n.t('helpers.statements_filter.subjects'),
      },
      {
        id: 2,
        name: i18n.t('helpers.statements_filter.all_subjects_with_marks'),
      },
    ]
  }

  /**
   *
   * @param {statementsFilterOption|null} value
   * @returns {boolean}
   */
  isActualSubjects(value) {
    if (isNil(value)) {
      return false
    }

    return this.actualSubjects.id === value.id
  }

  /**
   *
   * @param {statementsFilterOption|null} value
   * @returns {boolean}
   */
  isAllSubjectsWithMarks(value) {
    if (isNil(value)) {
      return false
    }

    return this.allSubjectsWithMarks.id === value?.id
  }

  get actualSubjects() {
    return this.options[0]
  }

  get allSubjectsWithMarks() {
    return this.options[1]
  }
}
