import '@/helpers/domains'
import '@/plugins/echarts'

import SentryHelper from '@/helpers/SentryHelper'
import { isShowcaseDomain } from '@/helpers/domain'
import { i18n } from '@/i18n/setup'
import { initPubSub } from '@/pubsub/subscriber'
import App from '@frontend/App.vue'
import { RouterUser } from '@frontend/RouterUser'
import { RouterUserShowcase } from '@frontend/RouterUserShowcase'
import { StoreUser } from '@frontend/StoreUser'
import { StoreUserShowcase } from '@frontend/StoreUserShowcase'
import PortalVue from 'portal-vue'
import VCalendar from 'v-calendar'
import Vue from 'vue'
import VueModal from 'vue-js-modal'
import { VueMasonryPlugin } from 'vue-masonry'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import VueSelect from 'vue-select'
import VueTippy from 'vue-tippy'
import VueToasted from 'vue-toasted'
import Vuex from 'vuex'

initPubSub()

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueMeta)
Vue.use(PortalVue) // TODO: с vue3 заменить на встроенный teleport
Vue.use(VueModal)

Vue.use(VueTippy, {
  onShow(options) {
    return Boolean(options.props.content)
  },
})

Vue.use(VueToasted, {
  position: 'bottom-center',
  duration: 2000,
})

Vue.use(VCalendar)
Vue.use(VueMasonryPlugin)
Vue.component('VSelect', VueSelect)

const createApp = async function () {
  let store
  let router

  if (isShowcaseDomain) {
    store = StoreUserShowcase.init()
    router = await RouterUserShowcase.init()

    await store.dispatch('locale/fetchLangOptions')
    store.dispatch('locale/setLangDefault')
    await store.dispatch('locale/changeLocale')
  } else {
    store = StoreUser.init()
    router = await RouterUser.init()
  }

  SentryHelper.init(router)

  return new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  })
}

createApp().then((app) => {
  document.querySelector('#preload')?.remove()
  app.$mount('#app')
})
