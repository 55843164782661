import { StatementsFilter } from '@frontend/helpers/StatementsFilter'

export default () => ({
  statements: {
    intramural: [],
    extramural: [],
  },
  academicYears: [],
  academicYear: null,

  statementIntramural: {
    statementsFilter: new StatementsFilter(),
    itemsFilter: null,
  },
})
