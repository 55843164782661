import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import { ApiAccountPromoOnlineLessons } from 'Api/rest/Api/Account/Promo/OnlineLessons'
import ApiOnlineLesson from 'Api/rest/Api/Account/Student/OnlineLesson'
import { ApiOnlineLessonCancel } from 'Api/rest/Api/Account/Student/OnlineLesson/Cancel'
import { ApiOnlineLessonSignUp } from 'Api/rest/Api/Account/Student/OnlineLesson/SignUp'
import { ApiTimestamp } from 'Api/system/Timestamp'
import { ApiOnlineLessonIsRunning } from 'ApiRest/Api/Account/Student/OnlineLesson/IsRunning'
import { ApiOnlineLessonJoin } from 'ApiRest/Api/Account/Student/OnlineLesson/Join'
import { ApiStudentOnlineLessonRecording } from 'ApiRest/Api/Account/Student/OnlineLesson/Recording'
import { ApiStudentOnlineLessonVideoTrack } from 'ApiRest/Api/Account/Student/OnlineLesson/Video/Track'
import ApiCalendarItems from 'ApiRest/Api/CalendarItems'
import { ApiUserAction } from 'ApiRest/Api/UserAction'
import { types as calendarTypes } from 'Helpers/Calendar'
import { trackActionTypes } from 'Helpers/const/trackActionTypes'
import { isWithinInterval, subMinutes } from 'date-fns'

export default {
  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   */
  resetState({ commit }) {
    commit('resetState')
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {string} payload.start
   * @param {string|null} payload.end
   */
  async fetchOnlineLessons({ commit }, { start, end = null }) {
    const { data, request } = await ApiCalendarItems.get({
      start,
      end,
      typeIds: [
        calendarTypes.ONLINE_LESSON,
      ],
    })

    if (request.status === HttpStatusCodes.NoContent) {
      commit('hideCalendar')
      commit('setOnlineLessons', [])
    } else {
      commit('setOnlineLessons', data)
    }
  },

  /**
   * @param {Object|undefined} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {number} lessonId
   * @returns {Object}
   */
  async fetchTheOnlineLesson({ state, commit }, lessonId) {
    if (state.detailLessons[lessonId]) {
      return state.detailLessons[lessonId]
    }

    const { data } = await ApiOnlineLesson.get(lessonId, {
      expand: 'conferenceType',
    })

    commit('setTheOnlineLesson', data)

    return data
  },

  /**
   * Логирование событий онлайн-урока.
   * @param {Object|undefined} context
   * @param {Object} context.state
   * @param {Object} payload
   * @param {number} payload.actionTypeId
   * @param {number} payload.lessonId
   * @returns {Promise<boolean>}
   */
  async trackOnlineLesson({ state }, { actionTypeId, lessonId }) {
    if (actionTypeId === trackActionTypes.ENTER_ONLINE_LESSON) {
      const { data: serverTimestamp } = await ApiTimestamp.get()

      // Серверное время по мск
      const serverTime = new Date(serverTimestamp * 1000)

      const currentLesson = state.lessons.find(
        (lesson) => lesson.id === lessonId,
      )

      // Начало урока минус 15 минут
      const start = subMinutes(
        new Date(`${currentLesson.dateStart}T${currentLesson.timeStart}`),
        15,
      )

      // Конец урока
      const end = new Date(`${currentLesson.dateEnd}T${currentLesson.timeEnd}`)

      const canTrackLessonVisit = isWithinInterval(serverTime, {
        start,
        end,
      })

      if (!canTrackLessonVisit) {
        return false
      }
    }

    await ApiUserAction.post({
      actionTypeId,
      entityId: Number(lessonId),
    })

    return true
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {number} lessonId
   */
  async signUpTheOnlineLesson({ commit, dispatch }, lessonId) {
    await ApiOnlineLessonSignUp.put(lessonId)

    await dispatch('trackOnlineLesson', {
      actionTypeId: trackActionTypes.SIGNUP_ONLINE_LESSON,
      lessonId,
    })

    commit('setSignUpTheOnlineLesson', lessonId)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @param {number} lessonId
   */
  async signCancelTheOnlineLesson({ commit, dispatch }, lessonId) {
    await ApiOnlineLessonCancel.put(lessonId)

    await dispatch('trackOnlineLesson', {
      actionTypeId: trackActionTypes.CANCEL_ONLINE_LESSON,
      lessonId,
    })

    commit('setSignCancelTheOnlineLesson', lessonId)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {number} lessonId
   */
  async fetchBigBlueButtonLessonIsRunning({ commit }, lessonId) {
    const { data } = await ApiOnlineLessonIsRunning.get(lessonId)

    commit('setTheOnlineLessonIsRunning', {
      lessonId,
      value: data,
    })
  },

  async fetchBigBlueButtonLessonUrl({ commit }, lessonId) {
    const data = ApiOnlineLessonJoin.get(lessonId)

    commit('setTheOnlineLessonUrl', data)
  },

  async fetchBigBlueButtonLessonTimestamp({ commit }) {
    const { data } = await ApiTimestamp.get()

    commit('setTheOnlineLessonTimestamp', data * 1000)
  },

  async fetchPromoOnlineLessons({ commit }, { periodStart, periodEnd = null }) {
    const { data } = await ApiAccountPromoOnlineLessons.get({
      periodStart,
      periodEnd,
    })

    commit('setPromoLessons', data)
  },

  /**
   *
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {number} payload.onlineLessonId
   * @param {number} payload.videoId
   * @returns {Promise<void>}
   */
  async fetchRecording({ commit }, { onlineLessonId, videoId }) {
    const { data } = await ApiStudentOnlineLessonRecording.get({
      onlineLessonId,
      videoId,
    })

    commit('setRecording', data)
  },

  trackRecording(context, { onlineLessonId, videoId }) {
    return ApiStudentOnlineLessonVideoTrack.post({
      onlineLessonId,
      videoId,
    })
  },
}
