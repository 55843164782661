import {
  studentFillDataTypeInitial,
  studentFillDataTypes,
} from '@frontend/helpers/studentFillDataType'

export default () => ({
  // Степень родства
  kinshipDegrees: [],
  // Гражданство
  citizenships: [],
  // Список всех стран
  countries: [],
  // Типы регистрации
  moscowRegistrationTypes: [],
  // Классы
  grades: [],
  // Тип заполнения анкеты студента
  studentFillDataTypes,
  // Способ подачи заявления
  submissionTypes: [],

  existingStudent: null,

  contractSimplified: {
    grade: null,
    submissionType: null,

    studentFillDataType: studentFillDataTypeInitial,

    kinship: {
      kinshipDegree: null,
    },

    agreedWithPolitics: false,
    agreedWithProcessingPDExtramural: false,

    student: {
      id: null,
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      birthDate: '',
      phone: '',
    },

    client: {
      address: null,

      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      phone: '',

      passport: null,

      citizenship: null,

      moscowRegistration: {
        type: null,
      },
    },
  },
})
