export const previewContentRoutes = [
  {
    path: '/content/library/preview',
    name: 'content-preview',
    component: () =>
      import(
        /* webpackChunkName: "content-preview" */
        '@frontend/pages/student/ContentPreview.vue'
      ),
  },
]
