import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export default new (class CertificationList extends ApiBase {
  /**
   * Аттестации по конкретному ученику
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    return this._GET(
      `/account/client/certifications?${UrlHelper.param(parameters)}`,
    )
  }
})()
