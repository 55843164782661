import storage from '@frontend/helpers/storage'
import { previewRoutes } from '@frontend/routes/preview'
import ApiBaseRole from 'ApiRest/Api/Account/Profile/BaseRole'

const getRouteNames = (routes) => {
  const result = []

  routes.forEach((route) => {
    if (route.children?.length > 0) {
      result.push(...getRouteNames(route.children))
    } else {
      result.push(route.name)
    }
  })

  return result
}

/**
 * Действия с кешем (localStorage)
 */
const actions = {
  // Очищение localStorage
  clearLocalStorage() {
    return storage.removeAll()
  },
}

const role = {
  /**
   * Роль: Менеджер или нет.
   * Есть возможность разлогиниться на другом домене,
   * надо всегда проверять через запрос.
   * @returns {Promise<boolean>}
   */
  isManager() {
    return ApiBaseRole.get()
      .then(({ data: { baseRole } }) => baseRole === 'manager')
      .catch(() => false)
  },
}

const auth = {
  /**
   * Для доступа к разделу превью
   * @param {string} pathName
   * @returns {boolean}
   */
  isPreview(pathName) {
    const previewRoutesNames = getRouteNames(previewRoutes)

    return previewRoutesNames.some((route) => pathName === route)
  },

  /**
   * Является ли страницей авторизации
   * @param {string} path
   * @returns {boolean}
   */
  isAuthPage(path) {
    const pages = [
      'registration',
      'demo-registration',
      'demo-fe-simple-registration',
      'educont-registration',
      'password-create', // Создание пароля при регистрации
      'confirm-email', // Подтверждение email при регистрации
    ]

    return pages.includes(path)
  },

  /**
   * Раздел запрещен для демо-ученика
   * @param {string} pathName
   * @returns {boolean}
   */
  isDemoUserAccessForbidden(pathName) {
    switch (pathName) {
      case 'student-profile':
      case 'online-lessons':
      case 'student-support':
      case 'student-support-questions':
      case 'student-support-ideas':
      case 'student-support-faq':
      case 'student-support-contacts':
      case 'student-support-the-question':
      case 'student-support-the-idea':
        return true

      default:
        return false
    }
  },
}

export default {
  actions,
  role,
  auth,
}
