import invoices from '@frontend/store/showcase/client/invoices/store'
import main from '@frontend/store/showcase/client/main/store'
import notifications from '@frontend/store/showcase/client/notifications/store'
import profile from '@frontend/store/showcase/client/profile/store'
import subscription from '@frontend/store/showcase/client/subscription/store'

export default {
  namespaced: true,
  modules: {
    invoices,
    main,
    notifications,
    profile,
    subscription,
  },
}
