import storage from '@frontend/helpers/storage'
import { ApiTimestamp } from 'Api/system/Timestamp'

const estimatedTime = 60 * 60 * 24 // 24 hours
const snapshotsTesting = 'snapshotsTesting'

const _getSnapshots = () => JSON.parse(storage.get(snapshotsTesting))

const _saveSnapshots = (payload) =>
  storage.set(snapshotsTesting, JSON.stringify(payload))

/**
 * @param {Object} params
 * @param {Array} params.questions
 * @param {number} params.attemptId
 * @param {number} params.timeCreated
 * @returns {boolean}
 */
const questionsSet = ({ attemptId, questions = [], timeCreated }) => {
  const data = _getSnapshots()
  const payload = {
    ...data,
    [attemptId]: {
      questions,
      timeCreated,
    },
  }

  _saveSnapshots(payload)

  return true
}

/**
 * @param {number} attemptId
 * @returns {boolean}
 */
const clearSnapshot = (attemptId) => {
  if (!attemptId) {
    return false
  }

  const data = _getSnapshots()

  if (data?.[attemptId]) {
    delete data[attemptId]
    _saveSnapshots(data)

    return true
  }

  return false
}

/**
 * @param {number} attemptId
 * @returns {Promise<Array|null>}
 */
const questionsGet = async (attemptId) => {
  const data = _getSnapshots()
  // Текущее серверное время, в сек
  const { data: currentTime } = await ApiTimestamp.get()

  if (data?.[attemptId]) {
    const snapshot = data[attemptId]

    if (currentTime - snapshot.timeCreated > estimatedTime) {
      clearSnapshot(attemptId)

      return null
    }

    return snapshot.questions
  }

  return null
}

/**
 * Определение каких вопросов недополучили с бэка
 * @param {Object} payload
 * @param {number} payload.attemptId
 * @param {Array} payload.questions
 * @returns {Promise<Array|boolean>}
 */
const questionsCompareWithSnapshot = async ({ attemptId, questions = [] }) => {
  const snapshot = await questionsGet(attemptId)

  if (snapshot && snapshot.length > questions.length) {
    const questionsIds = questions.map((i) => i.id)

    return snapshot.filter((i) => !questionsIds.includes(i.id))
  }

  return false
}

export default {
  questionsSet,
  questionsGet,
  clearSnapshot,
  questionsCompareWithSnapshot,
}
