import locale from '@frontend/store/common/locale/store'
import messages from '@frontend/store/common/messages/store'
import module from '@frontend/store/common/module/store'
import notifications from '@frontend/store/common/notifications/store'
import productAd from '@frontend/store/common/productAd/store'
import promoCampaign from '@frontend/store/common/promoCampaign/store'
import support from '@frontend/store/common/support/store'
import user from '@frontend/store/common/user/store'

export default {
  namespaced: true,

  modules: {
    locale,
    module,
    notifications,
    messages,
    promoCampaign,
    support,
    user,
    productAd,
  },
}
