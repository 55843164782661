import { RESET_STATE } from 'Helpers/ResetState'
import { uniq } from 'lodash'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setLocalesLoaded(state, payload) {
    state.localesLoaded = uniq(payload)
  },

  setLang(state, payload) {
    state.lang = payload
  },

  setLangOptions(state, payload) {
    state.langOptions = payload
  },
}
