import actions from '@frontend/store/courses/common/product/actions'
import getters from '@frontend/store/courses/common/product/getters'
import mutations from '@frontend/store/courses/common/product/mutations'
import state from '@frontend/store/courses/common/product/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
