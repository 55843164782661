import createState from '@frontend/store/client/subscription/state'
import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setSubscriptions(state, payload) {
    state.subscriptions = payload
  },

  updateSubscription(state, payload) {
    const subscriptionIndex = state.subscriptions.findIndex(
      (subscription) => subscription.id === payload.id,
    )

    Vue.set(state.subscriptions, subscriptionIndex, payload)
  },
}
