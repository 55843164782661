<template>
  <div :class="$style.slider">
    <div ref="slider" class="keen-slider">
      <component
        :is="slideComponent(slide)"
        v-bind="slideComponentAttrs(slide)"
        v-for="(slide, i) in slides"
        :key="i"
        class="keen-slider__slide"
      >
        <figure class="figure mb-0" @click="onClickSlide(slide)">
          <ElImage
            class="figure-img mb-0"
            :class="cssClassImage"
            :image-id="slide.image.id"
            :src="slide.image.url"
            :alt="slide.alt"
            :breakpoints="breakpoints"
          />
        </figure>
      </component>
    </div>

    <Controls
      v-if="isRendered.controls"
      class="mt-1 mt-lg-4"
      :slide-index-current="slideIndexCurrent"
      :slides-count="slidesCount"
      @click:dot="onClickDot"
      @click:prev="onClickPrev"
      @click:next="onClickNext"
    />
  </div>
</template>

<script>
import Controls from '@frontend/components/showcase/common/slider/Controls.vue'
import ElImage from '@frontend/ui/ElImage.vue'
import KeenSlider from 'keen-slider'
import { isString } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Slider',

  components: {
    Controls,
    ElImage,
  },

  props: {
    /**
     * @typedef {Object} Slide
     * @property {Image} image
     */

    /** @member {Slide[]} */
    slides: {
      type: Array,
      required: true,
    },

    slideIndexDefault: {
      type: Number,
      default: 0,
    },

    hideControls: {
      type: Boolean,
      default: false,
    },

    timeInterval: {
      type: Number,
      default: 5000,
    },

    isAutoPlay: {
      type: Boolean,
      default: true,
    },

    cssClassImage: {
      type: String,
      default: '',
    },

    breakpoints: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      slideIndexCurrent: 0,

      /** @member {Object|null} */
      slider: null,

      mouseOver: false,
      timeout: null,
    }
  },

  computed: {
    slidesCount() {
      return this.slider?.slides.length ?? 0
    },

    hasSlides() {
      return this.slides.length > 1
    },

    isRendered() {
      return {
        controls: !this.hideControls && this.hasSlides,
      }
    },
  },

  created() {
    this.init()
  },

  mounted() {
    this.initSlider()
  },

  beforeDestroy() {
    if (this.slider) {
      this.removeEventListeners()
      this.slider.destroy()
    }
  },

  methods: {
    init() {
      this.setDefaultSlide()
    },

    initSlider() {
      this.slider = new KeenSlider(
        this.$refs.slider,
        {
          initial: this.slideIndexCurrent,
          loop: true,

          slideChanged: (s) => {
            this.slideIndexCurrent = s.track.details.rel
          },
        },
        [
          this.initSliderListeners,
        ],
      )

      this.initEventListeners()
    },

    setDefaultSlide() {
      this.slideIndexCurrent = this.slideIndexDefault
    },

    isLinkSlide(slide) {
      return isString(slide?.url) && slide.url.length > 1
    },

    slideComponent(slide) {
      return this.isLinkSlide(slide) ? 'a' : 'div'
    },

    slideComponentAttrs(slide) {
      if (this.isLinkSlide(slide)) {
        return {
          href: slide.url,
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      }

      return {}
    },

    onClickSlide(slide) {
      this.$emit('click:slide', slide)
    },

    onClickDot(index) {
      this.slider.moveToIdx(index)
    },

    onClickPrev() {
      this.slider.prev()
    },

    onClickNext() {
      this.slider.next()
    },

    onMouseOverSlider() {
      if (this.isAutoPlay) {
        this.mouseOver = true
        this.clearSliderTimeout()
      }
    },

    onMouseOutSlider() {
      if (this.isAutoPlay) {
        this.mouseOver = false
        this.setSliderTimeout()
      }
    },

    onDragStartedSlider() {
      if (this.isAutoPlay) {
        this.clearSliderTimeout()
      }
    },

    onAnimationEndedSlider() {
      if (this.isAutoPlay) {
        this.setSliderTimeout()
      }
    },

    onUpdatedSlider() {
      if (this.isAutoPlay) {
        this.setSliderTimeout()
      }
    },

    onCreatedSlider() {
      if (this.isAutoPlay) {
        this.setSliderTimeout()
      }
    },

    clearSliderTimeout() {
      clearTimeout(this.timeout)
    },

    setSliderTimeout() {
      this.clearSliderTimeout()

      if (this.mouseOver) {
        return false
      }

      this.timeout = setTimeout(() => {
        if (this.hasSlides) {
          this.onClickNext()
        }
      }, this.timeInterval)

      return true
    },

    initSliderListeners(slider) {
      slider.on('created', this.onCreatedSlider)
      slider.on('dragStarted', this.onDragStartedSlider)
      slider.on('animationEnded', this.onAnimationEndedSlider)
      slider.on('updated', this.onUpdatedSlider)
    },

    initEventListeners() {
      this.slider.container.addEventListener(
        'mouseover',
        this.onMouseOverSlider,
      )
      this.slider.container.addEventListener('mouseout', this.onMouseOutSlider)
    },

    removeEventListeners() {
      this.slider.container.removeEventListener(
        'mouseover',
        this.onMouseOverSlider,
      )
      this.slider.container.removeEventListener(
        'mouseout',
        this.onMouseOutSlider,
      )
    },
  },
})
</script>

<style>
@import '~keen-slider/keen-slider.min.css';
</style>

<style lang="scss" module>
@import '~@frontend/scss/variables';

.slider {
  [class~='keen-slider'] {
    border-radius: 8px;
  }

  [class~='figure'] {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  [class~='figure-img'] img {
    width: 100%;
    object-fit: contain;

    @media (min-width: $sm) {
      object-fit: cover;
    }
  }
}
</style>
