import cart from '@frontend/store/showcase/student/cart/store'
import main from '@frontend/store/showcase/student/main/store'
import product from '@frontend/store/showcase/student/product/store'
import profile from '@frontend/store/showcase/student/profile/store'

export default {
  namespaced: true,

  modules: {
    cart,
    main,
    profile,
    product,
  },
}
