export const studentSubjectRoutes = [
  {
    path: 'subjects',
    name: 'subjects',
    component: () =>
      import(
        /* webpackChunkName: "subjects" */
        '@frontend/pages/student/subject/Subjects.vue'
      ),
  },
  {
    path: 'subjects/:subjectId(\\d+)/groups/:groupId(\\d+)',
    name: 'subject',
    component: () =>
      import(
        /* webpackChunkName: "subject" */
        '@frontend/pages/student/subject/TheSubject.vue'
      ),
  },
  {
    path: 'subjects/:subjectId(\\d+)/groups/:groupId(\\d+)/bibliography',
    name: 'subject-bibliography',
    component: () =>
      import(
        /* webpackChunkName: "subject-bibliography" */
        '@frontend/pages/student/subject/TheSubjectBibliography.vue'
      ),
  },
  {
    path: 'subjects/:subjectId(\\d+)/groups/:groupId(\\d+)/lesson/:lessonId(\\d+)/:homeworkSolutionId(\\d+)?',
    name: 'subject-lesson',
    component: () =>
      import(
        /* webpackChunkName: "subject-lesson" */
        '@frontend/pages/student/TheLesson.vue'
      ),
  },
]
