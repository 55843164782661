import { i18n } from '@/i18n/setup'
import ApiFamilyCertifications from 'ApiRest/Api/Account/Student/Test/FamilyCertification/List'
import ApiFeCertComplete from 'ApiRest/Api/Account/Student/Test/FeCertComplete'
import { showToast } from 'Helpers/toast'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @returns {Promise<*>}
   */
  async fetchCertifications({ commit }) {
    const { data } = await ApiFamilyCertifications.get()

    commit('setCertifications', data)
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @returns {Promise<*>}
   */
  async completeCertification({ commit }) {
    try {
      const { data } = await ApiFeCertComplete.put()

      commit('setTheCertification', data)

      // TODO: -> component
      showToast(
        i18n.t('store.student.toasted_message.certification_completed'),
        'success',
      )
    } catch (error) {
      // TODO: -> component
      const message =
        error?.response?.data?.message ||
        i18n.t('store.student.toasted_message.error_has_occurred')

      showToast(message, 'error')

      throw error
    }
  },
}
