import { RESET_STATE } from 'Helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setForm(state, value) {
    state.form = value
  },

  setFormProp(state, { key, value }) {
    state.form = {
      ...state.form,
      [key]: value,
    }
  },

  setIsAccepted(state, value) {
    state.isAccepted = value
  },

  setPrice(state, value) {
    state.price = value
  },

  setPromocodeDetails(state, value) {
    state.promocodeDetails = value
  },

  unsetPromocodeDetails(state) {
    state.promocodeDetails = null
  },

  setPaymentType(state, value) {
    state.paymentType = value
  },

  setInvoice(state, value) {
    state.invoice = value
  },

  unsetInvoice(state) {
    state.invoice = null
  },

  setCustomerReceipt(state, value) {
    state.customerReceipt = value
  },

  unsetCustomerReceipt(state) {
    state.customerReceipt = null
  },

  setOfferText(state, value) {
    state.offerText = value
  },

  setServiceDetails(state, value) {
    state.serviceDetails = value
  },
}
