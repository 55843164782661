import actions from '@frontend/store/showcase/common/category/actions'
import getters from '@frontend/store/showcase/common/category/getters'
import mutations from '@frontend/store/showcase/common/category/mutations'
import state from '@frontend/store/showcase/common/category/state'

export default {
  namespaced: true,

  actions,
  getters,
  mutations,
  state,
}
