import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setEvents(state, payload) {
    state.events = payload
  },

  addEvents(state, payload) {
    state.events = [
      ...state.events,
      ...payload,
    ]
  },

  setFilterValue(state, { key, value }) {
    state.filter[key].value = value
  },

  setFilterOptions(state, { key, value }) {
    state.filter[key].options = value
  },

  setPagination(state, payload) {
    state.pagination = payload
  },

  setTheEvent(state, payload) {
    state.theEvent = payload
  },
}
