import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setUserSettings(state, payload) {
    state.userSettings = payload
  },

  setProfile(state, payload) {
    state.profile = payload
  },

  setProfileValue(state, { key, value }) {
    Vue.set(state.profile, key, value)
  },

  setSchool(state, payload) {
    state.school = payload
  },

  setNotifications(state, payload) {
    state.notifications = payload
  },

  setEventsTelegramIsLinked(state, payload) {
    state.events.telegramBot.isLinked = payload
  },

  setEventsTelegramLink(state, payload) {
    state.events.telegramBot.link = payload
  },

  setEducontGrades(state, payload) {
    state.educontGrades = payload
  },

  setSelectedGrade(state, payload) {
    state.selectedGrade = payload
  },

  setClientPassword(state, payload) {
    state.clientPassword = payload
  },

  setClientToken(state, payload) {
    state.clientToken = payload
  },
}
