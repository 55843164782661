import { StoreUserShowcase } from '@frontend/StoreUserShowcase'
import authHelper from '@frontend/helpers/auth'
import { scrollBehavior } from '@frontend/helpers/scrollBehavior'
import NotFound from '@frontend/pages/NotFound.vue'
import { authRoutes } from '@frontend/routes/showcase/auth'
import { clientRoutes } from '@frontend/routes/showcase/client/clientRoutes'
import { guestRoutes } from '@frontend/routes/showcase/guest/guestRoutes'
import { studentRoutes } from '@frontend/routes/showcase/student/studentRoutes'
import { ApiCreateClientStudent } from 'ApiRest/Api/Account/Client/CreateClientStudent'
import { authUrl } from 'Helpers/const/authUrl'
import {
  redirectToAdminArea,
  redirectToAuth,
  redirectToShowcase,
} from 'Helpers/redirectHelper'
import { isUndefined } from 'lodash'
import VueRouter from 'vue-router'

export const RouterUserShowcaseNew = new (class {
  constructor() {
    this.router = null
  }

  async init() {
    const isClient = () =>
      StoreUserShowcase.store.getters['common/user/isClient']
    const isStudent = () =>
      StoreUserShowcase.store.getters['common/user/isStudent']
    const isManager = () =>
      StoreUserShowcase.store.getters['common/user/isManager']
    const isGuest = () =>
      StoreUserShowcase.store.getters['common/user/isAnonymous']

    await StoreUserShowcase.store.dispatch(
      'common/user/fetchIsAuthorized',
      null,
      {
        root: true,
      },
    )

    if (isStudent()) {
      await StoreUserShowcase.store.dispatch(
        'student/profile/fetchProfile',
        null,
        {
          root: true,
        },
      )
    }

    if (isClient()) {
      await StoreUserShowcase.store.dispatch(
        'client/profile/fetchProfile',
        null,
        {
          root: true,
        },
      )

      await ApiCreateClientStudent.post()
    }

    /**
     * Открыть раздел клиента
     * @param {Function} next
     * @returns {Function}
     */
    const displayClientSection = (next) =>
      next({
        name: 'client-showcase',
      })

    /**
     * Открыть раздел ученика
     * @param {Function} next
     * @returns {Function}
     */
    const displayStudentSection = (next) =>
      next({
        name: 'student-showcase',
      })

    /**
     * Открыть раздел гостя
     * @param {Function} next
     * @returns {Function}
     */
    const displayGuestUserSection = (next) =>
      next({
        name: 'guest-showcase',
      })

    const reloadPage = () => window.location.reload()

    const checkLoggedInUser = () => {
      const userId = StoreUserShowcase.store.state.common.user.id

      if (!userId) {
        return false
      }

      if (
        isClient() &&
        StoreUserShowcase.store.state.client.profile.profile.id !== userId
      ) {
        reloadPage()
      }

      if (
        isStudent() &&
        StoreUserShowcase.store.state.student.profile.profile.id !== userId
      ) {
        reloadPage()
      }

      return true
    }

    /**
     * @param {Object} to
     * @param {Function} next
     * @returns {Function}
     */
    const displayCurrentRoleShowcase = (to, next = null) => {
      const prefixes = new Map([
        [
          'client',
          'client-showcase',
        ],
        [
          'student',
          'student-showcase',
        ],
        [
          'guest',
          'guest-showcase',
        ],
      ])

      let prefix

      prefixes.forEach((routePrefix) => {
        if (to.name.startsWith(routePrefix)) {
          prefix = routePrefix
        }
      })

      /**
       * @param {string} role
       * @returns {Function}
       */
      const nextFor = (role) =>
        next({
          name: `${prefixes.get(role)}${to.name.substring(prefix.length)}`,
          params: to.params,
          query: to.query,
          replace: true,
        })

      if (prefix !== undefined) {
        if (isClient() && prefix !== prefixes.get('client')) {
          return nextFor('client')
        }

        if (isStudent() && prefix !== prefixes.get('student')) {
          return nextFor('student')
        }

        if (isGuest() && prefix !== prefixes.get('guest')) {
          const pathForGuest = to.fullPath.substring(
            to.fullPath.indexOf('/', 1),
          )

          return redirectToShowcase(pathForGuest)
        }
      }

      // Если гостевой роут не существует, отправить на главную страницу
      if (isGuest() && to.matched.length === 0) {
        return redirectToShowcase()
      }

      return next()
    }

    const setTargetUrl = (to) => {
      if (
        to.name === 'login' ||
        to.name === 'logout' ||
        to.name === 'not-found'
      ) {
        return window.location.origin
      }

      return window.location.href
    }

    /**
     * @param {{
     *   query: {
     *     targetUrl: string,
     *   },
     *   name: string,
     *   path: string,
     *   params: Object,
     * }} to
     * @param {{name: string}} from
     * @param {Function} next
     * @returns {Promise}
     */
    const processBeforeEach = async (to, from, next) => {
      const targetUrl = setTargetUrl(to)

      // Если переход на страницу login/logout произошел через роутинг (router-link, router.push()),
      // то делать редирект на authUrl

      if (to.name === 'login') {
        return redirectToAuth(authUrl.login, targetUrl)
      }

      if (to.name === 'logout') {
        return redirectToAuth(authUrl.logout, targetUrl)
      }

      if (to.name === 'password-reset') {
        return redirectToAuth(authUrl.recover)
      }

      // Если не авторизован и страница авторизации - пропустить
      if (authHelper.auth.isAuthPage(to.name) && isGuest()) {
        return next()
      }

      if (isManager()) {
        return redirectToAdminArea()
      }

      checkLoggedInUser()

      if (isUndefined(to.name)) {
        if (isClient()) {
          return displayClientSection(next)
        }

        if (isStudent()) {
          return displayStudentSection(next)
        }

        if (isGuest()) {
          return displayGuestUserSection(next)
        }
      } else {
        return displayCurrentRoleShowcase(to, next, targetUrl)
      }

      return redirectToAuth(authUrl.login, targetUrl)
    }

    const processAfterEach = async (to, from) => {
      if (from !== VueRouter.START_LOCATION) {
        const event = new Event('pageChange')

        document.dispatchEvent(event)
      }
    }

    this.router = new VueRouter({
      mode: 'history',

      routes: [
        ...authRoutes,
        ...clientRoutes,
        ...guestRoutes,
        ...studentRoutes,

        {
          path: '*',
          name: 'not-found',
          component: NotFound,
        },
      ],

      scrollBehavior,
    })

    this.router.beforeEach(processBeforeEach)
    this.router.afterEach(processAfterEach)

    return this.router
  }
})()
