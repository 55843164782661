import { LangSwitchMixin } from '@frontend/mixins/LangSwitchMixin'
import { identity, keyBy } from 'lodash'
import { mapGetters } from 'vuex'

export const LangSwitchModuleMixin = {
  mixins: [
    LangSwitchMixin,
  ],

  computed: {
    ...mapGetters('common/user', [
      'isClient',
      'isStudent',
    ]),

    ...mapGetters('common/module', [
      'hasModules',
      'moduleSettingValue',
    ]),

    isAvailableLang() {
      return this.lang ? !!this.langOptionsAvailableById[this.lang.id] : false
    },

    isAvailableLangSwitch() {
      if (this.hasModuleLangSwitch) {
        if (this.isClient) {
          return this.getLangSwitchModuleValue('showInClientAccount')
        }

        if (this.isStudent) {
          return this.getLangSwitchModuleValue('showInStudentAccount')
        }
      }

      return false
    },

    hasModuleLangSwitch() {
      return this.hasModules([
        'language-switcher',
      ])
    },

    hasLangOptions() {
      return this.langOptionsAvailable.length > 0
    },

    langOptionDefault() {
      return this.getLangSwitchModuleValue('defaultLanguage') ?? null
    },

    langOptionsSecond() {
      return this.getLangSwitchModuleValue('secondLanguage') ?? []
    },

    langOptionsFromModule() {
      return [
        this.langOptionDefault,
        ...this.langOptionsSecond,
      ].filter(identity)
    },

    langOptionsFromModuleById() {
      return keyBy(this.langOptionsFromModule, 'id')
    },

    langOptionsAvailable() {
      return this.langOptions.filter(
        (option) => !!this.langOptionsFromModuleById[option.id],
      )
    },

    langOptionsAvailableById() {
      return keyBy(this.langOptionsAvailable, 'id')
    },
  },

  watch: {
    langOptionsAvailable: {
      async handler() {
        await this.initLang()
      },

      deep: true,
    },
  },

  methods: {
    getLangSwitchModuleValue(key) {
      return this.moduleSettingValue('language-switcher', key)
    },

    /**
     * @public
     * @returns {Promise<void>}
     */
    async initLang() {
      if (!this.lang || !this.isAvailableLangSwitch || !this.isAvailableLang) {
        this.lang = this.langOptionsAvailable[0]
      } else {
        this.setLang(this.lang.code)
        await this.changeLocale()
      }
    },
  },
}
