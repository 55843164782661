export const clientCourseRoutes = [
  {
    path: 'courses',
    component: () =>
      import(
        /* webpackChunkName: "client-courses" */
        '@frontend/pages/courses/common/Courses.vue'
      ),
    meta: {
      isDisabledScroll: true,
    },
    children: [
      {
        path: '',
        name: 'client-courses',
        component: () =>
          import(
            /* webpackChunkName: "client-courses-home" */
            '@frontend/pages/courses/common/Home.vue'
          ),
      },

      {
        path: 'catalog',
        name: 'client-courses-catalog',
        component: () =>
          import(
            /* webpackChunkName: "client-courses-catalog" */
            '@frontend/pages/courses/common/Catalog.vue'
          ),
      },

      {
        path: 'products/:productId(\\d+)',
        name: 'client-courses-product',
        component: () =>
          import(
            /* webpackChunkName: "client-courses-product" */
            '@frontend/pages/courses/common/Product.vue'
          ),
      },

      {
        path: 'collections/:collectionId(\\d+)',
        name: 'client-courses-collection',
        component: () =>
          import(
            /* webpackChunkName: "client-courses-collection" */
            '@frontend/pages/courses/common/Collection.vue'
          ),
      },

      {
        path: 'cart',
        name: 'client-courses-cart',
        component: () =>
          import(
            /* webpackChunkName: "client-courses-cart" */
            '@frontend/pages/courses/common/Cart.vue'
          ),
      },

      {
        path: 'purchases',
        name: 'client-courses-purchases',
        component: () =>
          import(
            /* webpackChunkName: "client-courses-purchases" */
            '@frontend/pages/courses/common/Purchases.vue'
          ),
      },

      {
        path: 'purchases/:purchaseId(\\d+)',
        name: 'client-courses-the-purchase',
        component: () =>
          import(
            /* webpackChunkName: "client-courses-purchase" */
            '@frontend/pages/courses/client/Purchase.vue'
          ),
      },

      {
        path: 'purchases/:purchaseId(\\d+)/items/:purchaseItemId(\\d+)',
        name: 'client-courses-product-purchased',
        component: () =>
          import(
            /* webpackChunkName: "client-courses-product-purchased" */
            '@frontend/pages/courses/common/ProductPurchased.vue'
          ),
        children: [
          {
            path: 'materials',
            name: 'client-courses-product-purchased-materials',
            component: () =>
              import(
                /* webpackChunkName: "client-courses-product-purchased-materials" */
                '@frontend/pages/courses/common/Materials.vue'
              ),
          },
        ],
      },
      {
        path: '/cart/:offerId(\\d+)',
        name: 'client-courses-product-link',
        component: () =>
          import(
            /* webpackChunkName: "client-courses-product-link" */
            '@frontend/pages/courses/common/ProductLink.vue'
          ),
      },
    ],
  },
]
